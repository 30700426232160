import React from "react";

import { ICommonModalProps } from "../interfaces";

import "./../Modal.scss";

const ModalWrapper: React.FC<ICommonModalProps> = ({
  children,
  closeModal,
}): JSX.Element => {
  return (
    <>
      <div className="modal-wrapper" onClick={closeModal} />
      {children}
    </>
  );
};

export default ModalWrapper;
