interface IPage {
  name: string;
  link: string;
}

export const pages: Array<IPage> = [
  {
    name: "Communities",
    link: "/authority/communities/",
  },
  {
    name: "Networks",
    link: "/authority/networks/",
  },
  {
    name: "Reporters",
    link: "/authority/reporters/",
  },
];
