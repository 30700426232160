import React from "react";
import { CaseStatusKeys, NetworkSchemaKeys } from "@hapi.one/core-cli";

import AddressInfo from "../AddressInfo/AddressInfo";

interface IAddressesListInterface {
  caseStatus?: CaseStatusKeys;
  addresses: IAddressInterface[];
}

interface IAddressInterface {
  address: string;
  account: string;
  risk: number;
  category: string;
  network: string;
  networkName: string;
  reporter: string;
  confirmations: number;
  caseId: number;
  networkSchema: NetworkSchemaKeys;
}

const AddressesList: React.FC<IAddressesListInterface> = ({
  addresses,
  caseStatus,
}) => {
  return (
    <div className="addresses">
      {addresses &&
        addresses.map((address, index) => {
          return (
            <AddressInfo
              key={index}
              account={address.account}
              confirmations={address.confirmations}
              reporter={address.reporter}
              hash={address.address}
              group={address.category}
              riskScore={address.risk}
              networkName={address.networkName}
              caseId={address.caseId}
              networkSchema={address.networkSchema}
              caseStatus={caseStatus}
            />
          );
        })}
    </div>
  );
};

export default React.memo(AddressesList);
