import { PublicKey } from "@solana/web3.js";
import {
  NetworkSchemaKeys,
  ReporterRole,
  ReporterStatus,
} from "@hapi.one/core-cli";
import isTrc20 from "./validation-address-methods/isTrc20";
import { NetworkNameEnum, NetworkSchemaEnum } from "../enums";

export enum EAddressValidState {
  UNKNOWN = "UNKNOWN",
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export const recoverEnum = <K extends string, V>(
  enumObject: Record<K, V>,
  value: V
): K => {
  let result;

  for (const key of Object.keys(enumObject)) {
    if (JSON.stringify(key) === JSON.stringify(value)) {
      result = key as K;
    }
  }

  return result as K;
};

export const toSentenceCase = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const cropString = (text: string, step: number) => {
  const head = text.substring(0, step);
  const tail = text.substring(text.length - step, text.length);
  return `${head}...${tail}`;
};

export const isCaseButtonDisable = (
  status: string | null,
  role: string | null
) => {
  if (recoverEnum(ReporterStatus.Active, status)) {
    return recoverEnum(ReporterRole.Publisher, role) ||
      recoverEnum(ReporterRole.Authority, role)
      ? false
      : true;
  }
  return true;
};

export const isEditCaseDisable = (
  status: string | null,
  role: string | null,
  reporterPubkey: PublicKey,
  signerPubkey: PublicKey
) => {
  if (recoverEnum(ReporterStatus.Active, status)) {
    return (recoverEnum(ReporterRole.Publisher, role) &&
      reporterPubkey.equals(signerPubkey)) ||
      recoverEnum(ReporterRole.Authority, role)
      ? false
      : true;
  }
  return true;
};

export const isAddAddressButtonDisable = (
  status: string | null,
  role: string | null
): boolean => {
  if (recoverEnum(ReporterStatus.Active, status)) {
    return recoverEnum(ReporterRole.Tracer, role) ||
      recoverEnum(ReporterRole.Publisher, role) ||
      recoverEnum(ReporterRole.Authority, role)
      ? false
      : true;
  }
  return true;
};

export const isEditAddressButtonDisable = (
  status: string | null,
  role: string | null
): boolean => {
  if (recoverEnum(ReporterStatus.Active, status)) {
    return recoverEnum(ReporterRole.Authority, role) ? false : true;
  }
  return true;
};

export const isConfirmAddressButtonDisable = (
  status: string | null,
  role: string | null,
  reporterPubkey: PublicKey,
  signerPubkey: PublicKey,
  addressConfirmationCount: number
) => {
  if (
    recoverEnum(ReporterStatus.Active, status) &&
    !reporterPubkey.equals(signerPubkey) &&
    addressConfirmationCount < 1
  ) {
    return recoverEnum(ReporterRole.Tracer, role) ||
      recoverEnum(ReporterRole.Validator, role) ||
      recoverEnum(ReporterRole.Publisher, role) ||
      recoverEnum(ReporterRole.Authority, role)
      ? false
      : true;
  }
  return true;
};

export const isValidAddress = (
  query: string,
  networkSchema: NetworkSchemaKeys,
  networkName?: string
): EAddressValidState => {
  if (query.length === 0) {
    return EAddressValidState.UNKNOWN;
  }

  switch (networkSchema) {
    case NetworkSchemaEnum.Solana:
      try {
        return new PublicKey(query) && EAddressValidState.ALLOWED;
      } catch {
        return EAddressValidState.NOT_ALLOWED;
      }
    case NetworkSchemaEnum.Near:
      return query.match(
        /^(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/
      ) || query.match(/^[0-9a-f]{64}$/)
        ? EAddressValidState.ALLOWED
        : EAddressValidState.NOT_ALLOWED;
    case NetworkSchemaEnum.Ethereum:
      return query.match(/0x[a-fA-F0-9]{40}/)
        ? EAddressValidState.ALLOWED
        : EAddressValidState.NOT_ALLOWED;
    case NetworkSchemaEnum.Bitcoin:
      return query.match(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/)
        ? EAddressValidState.ALLOWED
        : EAddressValidState.NOT_ALLOWED;
    case NetworkSchemaEnum.Plain:
      if (networkName === NetworkNameEnum.Tron) {
        return isTrc20(query)
          ? EAddressValidState.ALLOWED
          : EAddressValidState.NOT_ALLOWED;
      }
      return EAddressValidState.UNKNOWN;
    default:
      return EAddressValidState.UNKNOWN;
  }
};
