import React, { useState } from "react";

import Button from "../Button/Button";
import { cropString } from "../../shared/helpers/helpers";

import "./Dropdown.scss";
import OutsideClickWrapper from "../OutsideClickWrapper/OutsideClickWrapper";

interface IDropdownMenu {
  buttonName?: string;
}

const DropdownMenu: React.FC<IDropdownMenu> = ({ buttonName, children }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const buttonHandler = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="dropdown-menu">
      {!showDropdown && (
        <Button
          icon={"ArrowRight"}
          secondIcon={"WalletIcon"}
          csName={"empty medium white right clear"}
          name={`${buttonName ? cropString(buttonName, 4) : "Dropdown"}`}
          clickAction={buttonHandler}
        />
      )}

      {showDropdown && (
        <OutsideClickWrapper clickHandler={buttonHandler}>
          <Button
            icon={"ArrowRight"}
            secondIcon={"WalletIcon"}
            csName={"empty medium white right clear"}
            name={`${buttonName ? cropString(buttonName, 4) : "Dropdown"}`}
            clickAction={buttonHandler}
          />
          <div className={"dropdown-content"}>{children}</div>
        </OutsideClickWrapper>
      )}
    </div>
  );
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
