import React from "react";

import Links from "../Links/Links";
import { links } from "../../resources/links";
import Copyright from "../Copyright/Copyright";
import { socialNetworks } from "../../resources/networks";
import SocialNetworks from "../SocialNetwork/SocialNetwork";

import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div>
        <Links links={links} />
      </div>
      <div>
        <SocialNetworks networks={socialNetworks} />
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
