import React from "react";

import Button from "../Button/Button";
import Search from "../Search/Search";

import "./SearchNButton.scss";

interface ISearchNButtonProps {
  buttonName?: string;
  buttonIcon?: string;
  buttonHide?: boolean;
  buttonDisable?: boolean;
  search: string;
  updateSearch: (value: string) => void;
  buttonFunc?(): void;
}

const SearchNButton: React.FC<ISearchNButtonProps> = ({
  buttonName,
  buttonIcon,
  buttonHide = false,
  buttonDisable,
  search,
  updateSearch,
  buttonFunc,
}) => {
  return (
    <div className="search-n-button">
      <Search updateSearch={updateSearch} search={search} />
      <div className="button-wrapper">
        {!buttonHide && buttonName && buttonIcon && buttonFunc && (
          <Button
            disabled={buttonDisable}
            name={buttonName}
            icon={buttonIcon}
            clickAction={buttonFunc}
            csName="medium icon-left"
          />
        )}
      </div>
    </div>
  );
};

export default SearchNButton;
