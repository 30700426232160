import { useEffect, useState } from "react";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchTokenBalance = (
  url = `https://api.devnet.solana.com`,
  publicKey: string,
  tokenMint: string
) => {
  const [tokenBalance, setTokenBalance] = useState<string>("0");
  const data = JSON.stringify({
    jsonrpc: "2.0",
    id: 1,
    method: "getTokenAccountsByOwner",
    params: [
      publicKey,
      {
        mint: tokenMint,
      },
      {
        encoding: "jsonParsed",
      },
    ],
  });

  useEffect(() => {
    const getTokenBalance = async () => {
      try {
        const _tokenBalance = await apiService.post(data).then((data) => {
          return data.result.value.length != 0
            ? data.result.value[0].account.data.parsed.info.tokenAmount
                .uiAmountString
            : "0";
        });

        setTokenBalance(_tokenBalance);
      } catch (error) {
        throw new Error(`Error during fetching cases: ${error}`);
      }
    };

    getTokenBalance();
  }, [publicKey]);

  return tokenBalance;
};
