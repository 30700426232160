import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CaseStatusKeys, NetworkSchemaKeys } from "@hapi.one/core-cli";

import Sticker from "../../../Sticker/Sticker";
import ConfirmBlock from "../../../ConfirmBlock/ConfirmBlock";
import BlockchainLabel from "../BlockchainLabel/BlockchainLabel";
import {
  openConfirmAddressModal,
  openEditAddressModal,
} from "../../../modals/shared";
import EditButton from "../../../Controls/EditButton/EditButton";
import { isEditAddressButtonDisable } from "../../../../shared/helpers/helpers";
import { useSolanaWallet } from "../../../../shared/providers/solana-wallet-provider";
import { useModalContext } from "../../../../shared/providers/modal-provider/modal-provider";
import { useConfirmAddressDisable } from "../../../../hooks/disableButtonHooks/useConfirmAddressDisable";
import { useHapiServiceContext } from "../../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./AddressInfo.scss";
import { useFetchAddressConfirmation } from "../../../../hooks/useFetchAddressConfirmation";

interface IAddressInfoProps {
  hash?: string;
  group: string;
  networkName?: string;
  riskScore: number;
  account?: string;
  reporter?: string;
  confirmations?: number;
  showConfirmCounter?: boolean;
  caseId?: number;
  networkSchema?: NetworkSchemaKeys;
  caseStatus?: CaseStatusKeys;
}

const AddressInfo: React.FC<IAddressInfoProps> = ({
  hash,
  account,
  riskScore,
  group,
  networkName,
  reporter,
  confirmations,
  caseId,
  networkSchema,
  showConfirmCounter = false,
  caseStatus,
}) => {
  const { t } = useTranslation();
  const { publicKey } = useSolanaWallet();
  const { showModal } = useModalContext();
  const { reporterInfo, hapiCore } = useHapiServiceContext();
  const addressConfirmationCount =
    account &&
    reporterInfo.account &&
    useFetchAddressConfirmation(account, reporterInfo.account.toString());

  const [confirmationState, setConfirmationState] = useState<number | null>(
    confirmations || confirmations === 0 ? confirmations : null
  );
  const [_riskScore, setRiskScore] = useState<number>(riskScore);
  const [_category, setCategory] = useState<string>(group);

  const isDisableByRole = useConfirmAddressDisable(
    hapiCore,
    reporterInfo,
    addressConfirmationCount || addressConfirmationCount === 0
      ? addressConfirmationCount
      : 1,
    publicKey,
    reporter
  );

  const isEditAddressDisable = isEditAddressButtonDisable(
    reporterInfo.reporterStatus,
    reporterInfo.reporterRole
  );

  const showConfirmWithButton =
    (confirmationState || confirmationState === 0) && hash && networkName;
  const showConfirmations =
    (confirmationState || confirmationState === 0) && showConfirmCounter;

  function handleRiskScore(value: number) {
    setRiskScore(value);
  }

  function handleCategory(value: string) {
    setCategory(value);
  }

  function handleConfirmations(value: number) {
    setConfirmationState(value);
  }

  function confirmAddress() {
    if (
      !hash ||
      !networkName ||
      !caseId ||
      !networkSchema ||
      confirmationState === null
    )
      return;
    openConfirmAddressModal(
      hash,
      riskScore,
      group,
      networkName,
      confirmationState,
      caseId,
      networkSchema,
      showModal,
      handleConfirmations
    );
  }

  function editAddress() {
    if (!hash || !networkName || !caseId || !networkSchema) return;
    openEditAddressModal(
      hash,
      riskScore,
      group,
      networkName,
      caseId,
      networkSchema,
      handleRiskScore,
      handleCategory,
      showModal
    );
  }

  return (
    <div className="address-info">
      {hash && (
        <p className={`${isEditAddressDisable ? "" : "with-edit"}`}>
          <span>{hash}</span>
          {!isEditAddressDisable && caseStatus === "Open" && (
            <EditButton editHandler={editAddress} />
          )}
        </p>
      )}
      <div>
        <span>
          {t("General.Components.RiskScore")}: {_riskScore} / 10
        </span>
        <Sticker name={_category} />
        {networkName && <BlockchainLabel name={networkName} />}
        {showConfirmations && <ConfirmBlock counter={confirmationState} />}
      </div>
      {showConfirmWithButton && (
        <ConfirmBlock
          counter={confirmationState}
          isButtonDisabled={
            isDisableByRole ||
            confirmationState !== confirmations ||
            caseStatus === "Closed"
          }
          isButtonConfirm
          buttonHandler={confirmAddress}
        />
      )}
    </div>
  );
};

export default React.memo(AddressInfo);
