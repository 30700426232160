import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { NetworkSchemaKeys } from "@hapi.one/core-cli";

import Table, { TR } from "../../../components/Table/Table";
import { EMPTY_TABLE_COL, timeOutTimeAmount } from "../../../shared/constants";
import { hideAdmin, showAdmin } from "../../../redux/actions";
import { INetworksInterface } from "../../../shared/interfaces";
import ToastNotify from "../../../components/Toast/ToastNotify";
import Pagination from "../../../components/Pagination/Pagination";
import { useFetchNetworks } from "../../../hooks/useFetchNetworks";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import SearchNButton from "../../../components/ SearchNButton/SearchNButton";
import {
  EModals,
  useModalContext,
} from "../../../shared/providers/modal-provider/modal-provider";
import { useSolanaWallet } from "../../../shared/providers/solana-wallet-provider";
import { useAuthorityContext } from "../../../shared/providers/authority-provider/AuthorityProvider";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./Networks.scss";

const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

const Networks: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { createNetwork, updateNetwork, getNetwork } = useAuthorityContext();
  const { showModal, closeModal } = useModalContext();
  const { publicKey } = useSolanaWallet();
  const { hapiCore, communityAccount } = useHapiServiceContext();

  const table = {
    thead: [
      t("Authority.Networks.Table.Head.Name"),
      t("Authority.Networks.Table.Head.Account"),
      EMPTY_TABLE_COL,
    ],
  };

  const [tbody, setTbody] = useState<TR[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const [communityAuthority, setCommunityAuthority] =
    useState<PublicKey | null>(null);
  const [isCommunityAuthority, setIsCommunityAuthority] =
    useState<boolean>(false);

  const itemsPerPage = 10;
  const skip: number = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;

  const { networks, networksCount } = useFetchNetworks(skip);

  const pages = Math.ceil(networksCount / itemsPerPage);

  const updatePaginationHandler = (value: number) => {
    setCurrentPage(value);
  };

  const createTbody = (networks: Array<INetworksInterface>) => {
    const tbodyNetworks = networks.map((network: INetworksInterface) => ({
      name: network.name,
      account: network.account,
      control: {
        name: "Edit",
        handler: () => openEditNetworkModal(network.name, network.schema),
        disabled: !isCommunityAuthority,
      },
    }));

    setTbody(tbodyNetworks);
  };

  const openAddNetworkModal = () => {
    showModal(EModals.ADD_NETWORK_MODAL, { handleConfirm: addNetwork });
  };

  function openEditNetworkModal(
    networkName: string,
    currentSchema: NetworkSchemaKeys
  ): void {
    showModal(EModals.EDIT_NETWORK_MODAL, {
      networkName,
      currentSchema,
      getNetwork,
      handleConfirm: editNetwork,
    });
  }

  async function addNetwork(
    networkName: string,
    schema: NetworkSchemaKeys,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ) {
    try {
      await createNetwork(
        networkName,
        schema,
        addressTracerReward,
        addressConfirmationReward,
        assetTracerReward,
        assetConfirmationReward
      );
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddNetwork.Messages.Notifications.NetworkAdded")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  async function editNetwork(
    networkName: string,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ) {
    try {
      await updateNetwork(
        networkName,
        addressTracerReward,
        addressConfirmationReward,
        assetTracerReward,
        assetConfirmationReward
      );
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddNetwork.Messages.Notifications.NetworkUpdated")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  useEffect(() => {
    dispatch(showAdmin());

    return () => {
      dispatch(hideAdmin());
    };
  }, []);

  useEffect(() => {
    if (networks.length === 0) return;

    createTbody(networks);
  }, [networks, isCommunityAuthority]);

  useEffect(() => {
    if (!hapiCore) return;

    async function getCommunityInfo() {
      const community = await hapiCore.account.community.fetch(
        communityAccount
      );
      setCommunityAuthority(community.authority);
    }
    getCommunityInfo();
  }, [hapiCore]);

  useEffect(() => {
    if (!communityAuthority || !publicKey) return;

    publicKey.equals(communityAuthority) && setIsCommunityAuthority(true);
  }, [communityAuthority, publicKey]);

  return (
    <div className="with-toast-alert">
      <SearchNButton
        search={searchString}
        updateSearch={setSearchString}
        buttonIcon="AddIcon"
        buttonName={t("Authority.Networks.Buttons.AddNetwork")}
        buttonFunc={openAddNetworkModal}
      />
      <Table thead={table.thead} tbody={tbody} />

      {!isNaN(pages) && pages && (
        <Pagination pages={pages} onPageChange={updatePaginationHandler} />
      )}
      <ToastContainer
        autoClose={5000}
        draggable={false}
        closeButton={false}
        position="top-center"
      />
    </div>
  );
};

export default Networks;
