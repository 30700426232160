import React from "react";

import { ReactComponent as ExternalLinkIcon } from "../../images/icon-pack/external-link.svg";

import "./Links.scss";

interface ILinks {
  link: string;
  title: string;
}

interface ILinksProps {
  links: Array<ILinks>;
}

const Links: React.FC<ILinksProps> = ({ links }) => {
  return (
    <div className="link-list">
      {links.map((link: ILinks, index: number) => {
        return (
          <span className="link-wrapper" key={index}>
            <a href={link.link} target="_blank" rel="noreferrer">
              <span className="text">{link.title}</span>
              <ExternalLinkIcon />
            </a>
          </span>
        );
      })}
    </div>
  );
};

export default Links;
