import React, { useEffect, useMemo, useState } from "react";
import { debounce, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { CaseStatus, NetworkSchemaKeys } from "@hapi.one/core-cli";

import Button from "../../Button/Button";
import { ICommonModalProps } from "../interfaces";
import { AddressData } from "../../../shared/types";
import { openConfirmAddressModal } from "../shared";
import TitleClose from "../Parts/TitleClose/TitleClose";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { categoryOptions } from "../../../shared/constants";
import TwoButtons from "../../Button/TwoButtons/TwoButtons";
import InputMessage from "../../Form/InputMessage/InputMessage";
import { INetworksInterface } from "../../../shared/interfaces";
import { useFetchNetworks } from "../../../hooks/useFetchNetworks";
import {
  EAddressValidState,
  isValidAddress,
  toSentenceCase,
} from "../../../shared/helpers/helpers";
import { useSolanaWallet } from "../../../shared/providers/solana-wallet-provider";
import { useModalContext } from "../../../shared/providers/modal-provider/modal-provider";
import { useConfirmAddressDisable } from "../../../hooks/disableButtonHooks/useConfirmAddressDisable";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "../AddAddressModal/AddAddressModal.scss";
import { useFetchAddressConfirmation } from "../../../hooks/useFetchAddressConfirmation";

enum EAddressCheckState {
  UNKNOWN = "UNKNOWN",
  EXIST = "EXIST",
  NOT_EXIST = "NOT_EXIST",
}

const risks: Array<number> = Array.from(Array(11).keys());

export interface IAddAddressModal {
  handleConfirm: any;
  checkAddress: any;
}

const AddAddressModal: React.FC<IAddAddressModal & ICommonModalProps> = ({
  handleConfirm,
  closeModal,
  checkAddress,
}) => {
  const { t } = useTranslation();
  const { publicKey } = useSolanaWallet();
  const { networks } = useFetchNetworks();
  const { showModal } = useModalContext();
  const { hapiCore, reporterInfo, getCase } = useHapiServiceContext();

  const [addressData, setAddressData] = useState<AddressData>();

  const [address, setAddress] = useState<string>("");

  const [addressAccount, setAddressAccount] = useState<string>("");

  const addressConfirmationCount =
    reporterInfo.account &&
    useFetchAddressConfirmation(
      addressAccount,
      reporterInfo.account.toString()
    );

  const [risk, changeRisk] = useState<any>(risks[0]);

  const [addressExist, setExist] = useState<EAddressCheckState>(
    EAddressCheckState.UNKNOWN
  );

  const [addressValid, setAddressValid] = useState<EAddressValidState>(
    EAddressValidState.UNKNOWN
  );

  const [category, changeCategory] = useState<string>(categoryOptions[0]);

  const [currentNetwork, changeCurrentNetwork] = useState<string>("ethereum");
  const [currentSchema, changeCurrentSchema] =
    useState<NetworkSchemaKeys>("Ethereum");

  const [showConfirmByStatus, setShowConfirmByStatus] =
    useState<boolean>(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeCurrentNetwork(e.target.value);
    changeCurrentSchema(
      e.target[e.target.selectedIndex].getAttribute(
        "data-schema"
      ) as NetworkSchemaKeys
    );
  };

  const handleCategories = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeCategory(e.target.value);
  };

  const handleRisk = (e: React.ChangeEvent<HTMLSelectElement>) =>
    changeRisk(e.target.value);

  const closeHandle = () => {
    closeModal();
    setExist(EAddressCheckState.UNKNOWN);
  };

  const handleAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
    setExist(EAddressCheckState.UNKNOWN);
  };

  const checkAddressHandle = async (
    _address: string,
    blockchain: string,
    networkSchema: NetworkSchemaKeys
  ) => {
    if (!_address || !blockchain || !networkSchema) {
      setExist(EAddressCheckState.UNKNOWN);
      return;
    }
    try {
      const _addressData = await checkAddress(
        _address,
        blockchain,
        networkSchema
      );

      const { status } = await getCase(_addressData.caseId.toNumber());
      setShowConfirmByStatus(isEqual(status, CaseStatus.Open));

      setAddressData(_addressData);
      setExist(EAddressCheckState.EXIST);
      setAddressAccount(_addressData.account);
    } catch (error) {
      setExist(EAddressCheckState.NOT_EXIST);
    }
    setAddressValid(isValidAddress(_address, networkSchema, blockchain));
  };

  const debouncedCheckAddress = useMemo(
    () => debounce(checkAddressHandle, 1000),
    []
  );

  useEffect(() => {
    debouncedCheckAddress(address, currentNetwork, currentSchema);

    return () => debouncedCheckAddress.cancel();
  }, [address, currentNetwork]);

  async function sampleAddAddress() {
    await handleConfirm(address, risk, currentNetwork, category, currentSchema);
  }

  function confirmAddress() {
    if (!addressData) return;

    openConfirmAddressModal(
      address,
      addressData.risk,
      toSentenceCase(Object.keys(addressData.category)[0]),
      currentNetwork,
      addressData.confirmations,
      addressData.caseId.toNumber(),
      currentSchema,
      showModal
    );
  }

  const isDisableByRole = useConfirmAddressDisable(
    hapiCore,
    reporterInfo,
    addressConfirmationCount || addressConfirmationCount === 0
      ? addressConfirmationCount
      : 1,
    publicKey,
    addressData && addressData.reporter
  );

  const buttonDisabled =
    addressExist !== EAddressCheckState.NOT_EXIST ||
    addressValid === EAddressValidState.NOT_ALLOWED ||
    !address;

  return (
    <ModalWrapper closeModal={closeModal}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddAddress.Title")}
          closeIcon={true}
          clickHandler={() => closeHandle()}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Select.SelectBlockchain")}
            <select
              className="medium"
              value={currentNetwork}
              onChange={handleSelectChange}
            >
              {networks.map((network: INetworksInterface, index: number) => {
                return (
                  <option
                    key={index}
                    data-schema={network.schema}
                    value={network.name}
                  >
                    {network.name.toUpperCase()}
                  </option>
                );
              })}
            </select>
          </label>

          <label
            className={`
              medium address 
              ${
                addressExist === EAddressCheckState.EXIST ||
                (addressValid === EAddressValidState.NOT_ALLOWED && "fail")
              }
              ${
                addressExist === EAddressCheckState.NOT_EXIST &&
                addressValid === EAddressValidState.ALLOWED &&
                "success"
              }
              ${
                address &&
                addressExist === EAddressCheckState.UNKNOWN &&
                "loading"
              }
            `}
          >
            {t("General.Labels.Input.InputAddress")}
            <input
              required
              value={address}
              onInput={handleAddress}
              type="text"
              className="medium"
            />
            {addressExist === EAddressCheckState.EXIST && (
              <InputMessage
                message={
                  !isDisableByRole && showConfirmByStatus
                    ? t(
                        "Shared.Modals.AddAddress.Messages.Hint.AddressExistConfirm"
                      )
                    : t("Shared.Modals.AddAddress.Messages.Hint.AddressExist")
                }
                success={false}
              />
            )}
            {addressValid === EAddressValidState.NOT_ALLOWED && (
              <InputMessage
                message={t(
                  "General.Messages.Hints.IncorrectAddressForNetwork",
                  { network: currentNetwork.toUpperCase() }
                )}
                success={false}
              />
            )}
          </label>
          {addressExist === EAddressCheckState.NOT_EXIST &&
            addressValid !== EAddressValidState.NOT_ALLOWED && (
              <label className="medium">
                {t("General.Labels.Select.Category")}
                <select
                  value={category}
                  className="medium"
                  onChange={handleCategories}
                >
                  {categoryOptions.map((_category: string) => {
                    return (
                      <option
                        key={_category}
                        value={_category}
                        defaultValue={categoryOptions[0]}
                      >
                        {_category}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
          {addressExist === EAddressCheckState.NOT_EXIST &&
            addressValid !== EAddressValidState.NOT_ALLOWED && (
              <label className="medium">
                {t("General.Labels.Select.Risk")}
                <select className="medium" value={risk} onChange={handleRisk}>
                  {risks.map((risk: number, index) => {
                    return (
                      <option key={index} value={risk} defaultValue={risks[0]}>
                        {risk}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
        </div>
        {!isDisableByRole &&
          addressExist === EAddressCheckState.EXIST &&
          showConfirmByStatus && (
            <Button
              icon="ConfirmIcon"
              name="Confirm Address"
              csName="full-width empty icon-left medium confirm-address"
              clickAction={confirmAddress}
            />
          )}
        <TwoButtons
          firstButton={{
            name: t("General.Buttons.Cancel"),
            func: () => closeHandle(),
          }}
          secondButton={{
            name: t("Shared.Modals.AddAddress.Buttons.AddAddress"),
            func: () => sampleAddAddress(),
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddAddressModal;
