import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NetworkSchemaKeys } from "@hapi.one/core-cli";

import ToastNotify from "../../Toast/ToastNotify";
import { ICommonModalProps } from "../interfaces";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import TitleClose from "../Parts/TitleClose/TitleClose";
import TwoButtons from "../../Button/TwoButtons/TwoButtons";
import AddressInfo from "../../Case/Parts/AddressInfo/AddressInfo";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import InputPresentation from "../../Form/InputPresentation/InputPresentation";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./ConfirmAddressModal.scss";

const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

export interface IConfirmAddressModal {
  hash: string;
  riskScore: number;
  group: string;
  networkName: string;
  confirmations: number;
  caseId: number;
  networkSchema: NetworkSchemaKeys;
  handleConfirmations?: (value: number) => void;
}

const ConfirmAddressModal: React.FC<
  IConfirmAddressModal & ICommonModalProps
> = ({
  hash,
  riskScore,
  group,
  networkName,
  confirmations,
  caseId,
  networkSchema,
  closeModal,
  handleConfirmations,
}) => {
  const { t } = useTranslation();
  const { confirmAddress } = useHapiServiceContext();

  const closeHandle = () => closeModal();

  async function confirm() {
    try {
      await confirmAddress(networkName, caseId, hash, networkSchema);
      handleConfirmations && handleConfirmations(confirmations + 1);
      closeHandle();
      notify(
        true,
        t(
          "Shared.Modals.ConfirmAddress.Messages.Notifications.AddressConfirmed"
        )
      );
    } catch (error) {
      console.log("Error", error);
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal confirm_address">
        <TitleClose
          title={t("Shared.Modals.ConfirmAddress.Title")}
          closeIcon={true}
          clickHandler={closeHandle}
        />

        <div className="text-container clear-margin">
          {t("Shared.Modals.ConfirmAddress.Description")}
        </div>

        <div className="input-wrapper">
          <InputPresentation value={hash} />
          <AddressInfo
            group={group}
            riskScore={riskScore}
            networkName={networkName}
            confirmations={confirmations}
            showConfirmCounter={true}
          />
        </div>

        <TwoButtons
          firstButton={{
            name: t("General.Buttons.Cancel"),
            func: closeHandle,
          }}
          secondButton={{
            name: t("Shared.Modals.ConfirmAddress.Buttons.ConfirmAddress"),
            func: confirm,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default ConfirmAddressModal;
