import React from "react";
import { useTranslation } from "react-i18next";

import ClearSearch from "./Parts/ClearSearch";

import "./Search.scss";

interface ISearch {
  updateSearch: (value: string) => void;
  search: string;
}

const Search: React.FC<ISearch> = ({ updateSearch, search }) => {
  const { t } = useTranslation();
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateSearch(e.target.value);
  return (
    <div className="search">
      <input
        type="text"
        value={search}
        onInput={handleSearch}
        placeholder={t("General.Search.Search")}
      />

      {search && <ClearSearch clickAction={() => updateSearch("")} />}
    </div>
  );
};

export default Search;
