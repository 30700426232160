export enum ChangeStatus {
  UNCHANGED = "UNCHANGED",
  FALSE_TO_TRUE = "FALSE_TO_TRUE",
  TRUE_TO_FALSE = "TRUE_TO_FALSE",
}

export enum NetworkSchemaEnum {
  Solana = "Solana",
  Near = "Near",
  Ethereum = "Ethereum",
  Bitcoin = "Bitcoin",
  Plain = "Plain",
}

export enum AddressCheckState {
  UNKNOWN = "UNKNOWN",
  EXIST = "EXIST",
  NOT_EXIST = "NOT_EXIST",
}

export enum NetworkNameEnum {
  Tron = "tron",
}
