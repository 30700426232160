import React from "react";

import "./DeleteButton.scss";

import { ReactComponent as DeleteIcon } from "../../../images/icon-pack/delete.svg";

const DeleteButton: React.FC = () => {
  // const { open: confirmDeletingModal } = useModal("ConfirmDeleting");

  return (
    <button
      // onClick={confirmDeletingModal}
      className="delete-button"
    >
      <DeleteIcon />
    </button>
  );
};

export default DeleteButton;
