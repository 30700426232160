import React from "react";

import "./ToastAlert.scss";

import { ReactComponent as SuccessIcon } from "../../images/icon-pack/check.svg";
import { ReactComponent as FailIcon } from "../../images/icon-pack/close-icon.svg";

interface IToastNotify {
  message: string;
  success: boolean;
}

export const ToastNotify: React.FC<IToastNotify> = (props) => {
  return (
    <div className={props.success ? "sucess" : "fail"}>
      {props.success ? <SuccessIcon /> : <FailIcon />}
      <span>{props.message}</span>
    </div>
  );
};

export default ToastNotify;
