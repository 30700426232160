import React from "react";

import "./ClearSearch.scss";

import { ReactComponent as ClearIcon } from "../../../images/icon-pack/clear-search.svg";

interface ICLearSearch {
  clickAction(): void;
}

const ClearSearch: React.FC<ICLearSearch> = (props) => {
  return (
    <span className="clear-search" onClick={props.clickAction}>
      <ClearIcon />
    </span>
  );
};

export default ClearSearch;
