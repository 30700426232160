import React, { useEffect, useState } from "react";
import { ChangeStatus } from "../../../shared/enums";

import "./ToggleButton.scss";

interface ToggleButtonProps {
  checked: boolean;
  text?: string;
  handler?(status: ChangeStatus): void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  text,
  checked,
  handler,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const changeHandler = () => {
    const isCheckedChanged = !isChecked;

    if (handler) {
      if (checked === isCheckedChanged) {
        handler(ChangeStatus.UNCHANGED);
      } else if (checked !== isCheckedChanged && isCheckedChanged === false) {
        handler(ChangeStatus.TRUE_TO_FALSE);
      } else {
        handler(ChangeStatus.FALSE_TO_TRUE);
      }
    }
    setIsChecked((prev) => !prev);
  };

  return (
    <div className={`toggle-wrapper ${text ? "with-text" : ""}`}>
      {text && <span>{text}</span>}
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={changeHandler} />
        <span className="slider round" />
      </label>
    </div>
  );
};

export default ToggleButton;
