import React, { createContext, useContext, useCallback } from "react";
import {
  bufferFromString,
  NetworkSchema,
  NetworkSchemaKeys,
  ReporterRole,
} from "@hapi.one/core-cli";
import { Token, TOKEN_PROGRAM_ID, u64, MintLayout } from "@solana/spl-token";
import { web3 } from "@project-serum/anchor";

import { useSolanaWallet } from "../solana-wallet-provider";
import { useHapiServiceContext } from "../hapi-service-provider/HapiServiceProvider";
import { useSendTransactionContext } from "../send-transaction-provider/SendTransactionProvider";
import {
  PublicKey,
  TransactionInstruction,
  Keypair,
  SystemProgram,
} from "@solana/web3.js";
import { ChangeStatus } from "../../enums";
import { AddressData } from "../../types";

interface IAuthorityContext {
  addReporter(
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string
  ): Promise<string>;
  updateReporter(
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string,
    freezeReporter: ChangeStatus
  ): Promise<string>;
  getReporterAddress(reporterPubkey: PublicKey): Promise<AddressData>;
  createNetwork(
    networkName: string,
    schema: NetworkSchemaKeys,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ): Promise<string>;
  updateNetwork(
    networkName: string,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ): Promise<string>;
  updateCommunity(
    stakeUnlockEpochs: string,
    confirmationThreshold: number,
    validatorStake: string,
    tracerStake: string,
    fullStake: string,
    authorityStake: string
  ): Promise<string>;
  setCommunityAuthority(newAuthority: PublicKey): Promise<string>;
  getReporter(pubkey: string): Promise<any>;
  getNetwork(name: string): Promise<any>;
  getCommunity(): Promise<any>;
}

export const AuthorityContext = createContext<IAuthorityContext>(
  {} as IAuthorityContext
);

export const useAuthorityContext = () => useContext(AuthorityContext);

export const AuthorityProvider: React.FC = ({ children }) => {
  const MINT_SIZE = MintLayout.span;
  const { hapiCore, communityAccount, connection } = useHapiServiceContext();
  const { sendTransaction } = useSendTransactionContext();
  const { signTransaction, publicKey } = useSolanaWallet();

  const getReporterAddress = async (reporterPubkey: PublicKey) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during getting reporter address");

    const [reporterAccount, bump] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      reporterPubkey
    );

    const reporterResult = await hapiCore.account.reporter.fetch(
      reporterAccount
    );

    return reporterResult;
  };

  const addReporter = async (
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during creating reporter");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const reporterPubkey = new web3.PublicKey(reporterPublicKey);

    const [reporterAccount, bump] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      reporterPubkey
    );

    const nameBuffer = bufferFromString(name, 32);

    const transaction = hapiCore.transaction.createReporter(
      role,
      nameBuffer,
      bump,
      {
        accounts: {
          authority: publicKey,
          community: communityAccount,
          reporter: reporterAccount,
          pubkey: reporterPubkey,
          systemProgram: web3.SystemProgram.programId,
        },
        signers: [],
      }
    );

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const updateReporter = async (
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string,
    freezeReporter: ChangeStatus
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during updating reporter");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const reporterPubkey = new web3.PublicKey(reporterPublicKey);

    const [reporterAccount, bump] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      reporterPubkey
    );

    const nameBuffer = bufferFromString(name, 32);

    const instructions: TransactionInstruction[] =
      freezeReporter === ChangeStatus.FALSE_TO_TRUE
        ? await freezeReporterInstruction(reporterAccount)
        : freezeReporter === ChangeStatus.TRUE_TO_FALSE
        ? await unfreezeReporterInstruction(reporterAccount)
        : [];

    const transaction = await hapiCore.transaction.updateReporter(
      role,
      nameBuffer,
      {
        accounts: {
          authority: publicKey,
          community: communityAccount,
          reporter: reporterAccount,
        },
        signers: [],
        postInstructions: instructions,
      }
    );

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const freezeReporterInstruction = (reporterAccount: PublicKey) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Something went wrong");

    const instruction = hapiCore.instruction.freezeReporter({
      accounts: {
        authority: publicKey,
        community: communityAccount,
        reporter: reporterAccount,
      },
      signers: [],
    });
    return [instruction];
  };

  const unfreezeReporterInstruction = (reporterAccount: PublicKey) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Something went wrong");

    const instruction = hapiCore.instruction.unfreezeReporter({
      accounts: {
        authority: publicKey,
        community: communityAccount,
        reporter: reporterAccount,
      },
      signers: [],
    });
    return [instruction];
  };

  const createNetwork = async (
    networkName: string,
    schema: NetworkSchemaKeys,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during creating reporter");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const nameBuffer = bufferFromString(networkName, 32);
    const addressTracerRewardConvert = new u64(addressTracerReward);
    const addressConfirmationRewardConvert = new u64(addressConfirmationReward);
    const assetTracerRewardConvert = new u64(assetTracerReward);
    const assetConfirmationRewardConvert = new u64(assetConfirmationReward);

    const [networkAccount, networkBump] = await hapiCore.pda.findNetworkAddress(
      communityAccount,
      networkName
    );

    const [rewardSignerAccount, rewardSignerBump] =
      await hapiCore.pda.findNetworkRewardSignerAddress(networkAccount);

    const mint = Keypair.generate();
    const lamports = await Token.getMinBalanceRentForExemptMint(connection);

    const tokenAccount = SystemProgram.createAccount({
      fromPubkey: publicKey,
      newAccountPubkey: mint.publicKey,
      space: MINT_SIZE,
      lamports,
      programId: TOKEN_PROGRAM_ID,
    });

    const mintInstruction = Token.createInitMintInstruction(
      TOKEN_PROGRAM_ID,
      mint.publicKey,
      9,
      publicKey,
      null
    );

    const transaction = hapiCore.transaction.createNetwork(
      nameBuffer,
      NetworkSchema[schema],
      addressTracerRewardConvert,
      addressConfirmationRewardConvert,
      assetTracerRewardConvert,
      assetConfirmationRewardConvert,
      networkBump,
      rewardSignerBump,
      {
        accounts: {
          authority: publicKey,
          community: communityAccount,
          rewardMint: mint.publicKey,
          rewardSigner: rewardSignerAccount,
          network: networkAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
        },
        signers: [mint],
        preInstructions: [tokenAccount, mintInstruction],
      }
    );

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;
    transaction.partialSign(mint);

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const updateNetwork = async (
    networkName: string,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during editing reporter");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const addressTracerRewardConvert = new u64(addressTracerReward);
    const addressConfirmationRewardConvert = new u64(addressConfirmationReward);
    const assetTracerRewardConvert = new u64(assetTracerReward);
    const assetConfirmationRewardConvert = new u64(assetConfirmationReward);

    const [networkAccount] = await hapiCore.pda.findNetworkAddress(
      communityAccount,
      networkName
    );

    const transaction = hapiCore.transaction.updateNetwork(
      addressTracerRewardConvert,
      addressConfirmationRewardConvert,
      assetTracerRewardConvert,
      assetConfirmationRewardConvert,
      {
        accounts: {
          authority: publicKey,
          community: communityAccount,
          network: networkAccount,
        },
        signers: [],
      }
    );

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const updateCommunity = async (
    stakeUnlockEpochs: string,
    confirmationThreshold: number,
    validatorStake: string,
    tracerStake: string,
    fullStake: string,
    authorityStake: string
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during editing community");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const transaction = hapiCore.transaction.updateCommunity(
      new u64(stakeUnlockEpochs),
      confirmationThreshold,
      new u64(validatorStake),
      new u64(tracerStake),
      new u64(fullStake),
      new u64(authorityStake),
      {
        accounts: {
          authority: publicKey,
          community: communityAccount,
        },
        signers: [],
      }
    );

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const setCommunityAuthority = async (newAuthority: PublicKey) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Error during creating community authority");

    if (!signTransaction)
      throw new Error("Wallet does not support transaction signing!");

    const transaction = hapiCore.transaction.setCommunityAuthority({
      accounts: {
        authority: publicKey,
        community: communityAccount,
        newAuthority,
      },
      signers: [],
    });

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const getReporter = useCallback(
    async (pubkey: string) => {
      const [reporterAccount] = await hapiCore.pda.findReporterAddress(
        communityAccount,
        new PublicKey(pubkey)
      );

      const reporterResult = await hapiCore.account.reporter.fetch(
        reporterAccount
      );

      return reporterResult;
    },
    [hapiCore]
  );

  const getNetwork = useCallback(
    async (name: string) => {
      const [networkAccount] = await hapiCore.pda.findNetworkAddress(
        communityAccount,
        name
      );

      const networkResult = await hapiCore.account.network.fetch(
        networkAccount
      );

      return networkResult;
    },
    [hapiCore]
  );

  const getCommunity = useCallback(async () => {
    const communityResult = await hapiCore.account.community.fetch(
      communityAccount
    );

    return communityResult;
  }, [hapiCore]);
  return (
    <AuthorityContext.Provider
      value={{
        addReporter,
        updateReporter,
        getReporterAddress,
        createNetwork,
        updateNetwork,
        updateCommunity,
        setCommunityAuthority,
        getReporter,
        getNetwork,
        getCommunity,
      }}
    >
      {children}
    </AuthorityContext.Provider>
  );
};
