import { useEffect, useState } from "react";
import { NetworkSchemaKeys } from "@hapi.one/core-cli";
import getConfig from "../configs/config";
import apiService from "../shared/services/api-service.ts/api-service";

interface IAddresses {
  address: string;
  account: string;
  category: string;
  risk: number;
  network: string;
  networkName: string;
  reporter: string;
  confirmations: number;
  caseId: number;
  networkSchema: NetworkSchemaKeys;
}
export const useFetchCaseAddresses = (
  shouldLoad: boolean,
  caseId: number,
  skip = 0
) => {
  const config = getConfig();

  const [addresses, setAddresses] = useState<IAddresses[]>([]);
  const [addressesCount, setAddressesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldLoad) return;
    const fetchAddresses = async () => {
      try {
        setIsLoading(true);
        const { list, count } = await apiService.get(
          `address?caseId=${caseId}&community=${config.communityAccount}&take=10&skip=${skip}`
        );
        setIsLoading(false);
        setAddresses(list);
        setAddressesCount(count);
      } catch (error) {
        throw new Error(
          `Error during fetching addresses for case ${caseId}: ${error}`
        );
      }
    };

    fetchAddresses();
  }, [shouldLoad, caseId, skip]);

  return { addresses, isLoading, addressesCount };
};
