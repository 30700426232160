import React, { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";

import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import { ICommonModalProps } from "../../interfaces";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";
import InputPresentation from "../../../Form/InputPresentation/InputPresentation";
import {
  EModals,
  useModalContext,
} from "../../../../shared/providers/modal-provider/modal-provider";

export interface IEditCommunityModal {
  account: string;
  authority: string;
  getCommunity(): Promise<any>;
  handleConfirm(
    stakeUnlockEpochs: string,
    confirmationThreshold: number,
    validatorStake: string,
    tracerStake: string,
    fullStake: string,
    authorityStake: string
  ): void;
  setAuthority(newAuthority: PublicKey): void;
}

interface CommunityData {
  stakeUnlockEpochs: string;
  confirmationThreshold: number;
  validatorStake: string;
  tracerStake: string;
  fullStake: string;
  authorityStake: string;
}

const EditCommunityModal: React.FC<IEditCommunityModal & ICommonModalProps> = ({
  account,
  authority,
  getCommunity,
  handleConfirm,
  setAuthority,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { showModal } = useModalContext();

  const [communityData, setCommunityData] = useState<CommunityData>({
    stakeUnlockEpochs: "",
    confirmationThreshold: 0,
    validatorStake: "",
    tracerStake: "",
    fullStake: "",
    authorityStake: "",
  });

  const [stakeUnlockEpochs, setStakeUnlockEpochs] = useState<string>(
    communityData.stakeUnlockEpochs
  );
  const [confirmationThreshold, setConfirmationThresholdState] =
    useState<number>(communityData.confirmationThreshold);
  const [validatorStake, setValidatorStake] = useState<string>(
    communityData.validatorStake
  );
  const [tracerStake, setTracerStake] = useState<string>(
    communityData.tracerStake
  );
  const [fullStake, setFullStake] = useState<string>(communityData.fullStake);
  const [authorityStake, setAuthorityStake] = useState<string>(
    communityData.authorityStake
  );

  const closeHandle = () => closeModal();

  const handleStakeUnlockEpochs = (e: React.ChangeEvent<HTMLInputElement>) =>
    setStakeUnlockEpochs(e.target.value);

  const handleConfirmationThresholdState = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setConfirmationThresholdState(Number(e.target.value));

  const handleValidatorStake = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValidatorStake(e.target.value);

  const handleTracerStake = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTracerStake(e.target.value);

  const handleFullStake = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFullStake(e.target.value);

  const handleAuthorityStake = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAuthorityStake(e.target.value);

  const openSetCommunityAuthorityModal = () => {
    showModal(EModals.SET_COMMUNITY_AUTHORITY_MODAL, {
      authority,
      handleConfirm: setAuthority,
    });
  };

  useEffect(() => {
    getCommunityData();
  }, []);

  useEffect(() => {
    setStakeUnlockEpochs(communityData.stakeUnlockEpochs);
    setConfirmationThresholdState(communityData.confirmationThreshold);
    setValidatorStake(communityData.validatorStake);
    setTracerStake(communityData.tracerStake);
    setFullStake(communityData.fullStake);
    setAuthorityStake(communityData.authorityStake);
  }, [communityData]);

  async function getCommunityData() {
    try {
      const data = await getCommunity();

      setCommunityData({
        stakeUnlockEpochs: data.stakeUnlockEpochs.toString(),
        confirmationThreshold: data.confirmationThreshold,
        validatorStake: data.validatorStake.toString(),
        tracerStake: data.tracerStake.toString(),
        fullStake: data.fullStake.toString(),
        authorityStake: data.authorityStake.toString(),
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function editCommunity() {
    try {
      await handleConfirm(
        stakeUnlockEpochs,
        confirmationThreshold,
        validatorStake,
        tracerStake,
        fullStake,
        authorityStake
      );
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  const buttonDisabled =
    !stakeUnlockEpochs ||
    !confirmationThreshold ||
    !validatorStake ||
    !tracerStake ||
    !fullStake ||
    !authorityStake ||
    (communityData.stakeUnlockEpochs === stakeUnlockEpochs &&
      communityData.confirmationThreshold === confirmationThreshold &&
      communityData.validatorStake === validatorStake &&
      communityData.tracerStake === tracerStake &&
      communityData.fullStake === fullStake &&
      communityData.authorityStake === authorityStake);

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddCommunity.EditTitle")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.Account")}
            <InputPresentation value={account} className="with-label" />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.Authority")}
            <InputPresentation value={authority} className="with-label" />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.StakeUnlockEpochs")}
            <input
              required
              type="number"
              value={stakeUnlockEpochs}
              onInput={handleStakeUnlockEpochs}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.ConfirmationThreshold")}
            <input
              required
              type="number"
              value={confirmationThreshold}
              onInput={handleConfirmationThresholdState}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.ValidatorStake")}
            <input
              required
              type="number"
              value={validatorStake}
              onInput={handleValidatorStake}
              className="medium"
            />
          </label>
          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.TracerStake")}
            <input
              required
              type="number"
              value={tracerStake}
              onInput={handleTracerStake}
              className="medium"
            />
          </label>
          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.PublisherStake")}
            <input
              required
              type="number"
              value={fullStake}
              onInput={handleFullStake}
              className="medium"
            />
          </label>
          <label className="medium">
            {t("Shared.Modals.AddCommunity.Labels.AuthorityStake")}
            <input
              required
              type="number"
              value={authorityStake}
              onInput={handleAuthorityStake}
              className="medium"
            />
          </label>
        </div>
        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t("Shared.Modals.AddCommunity.Buttons.SaveCommunity"),
            func: editCommunity,
            disabled: buttonDisabled,
          }}
          additionalButton={{
            name: t("Shared.Modals.SetCommunityAuthority.Title"),
            csName: "full-width empty icon-left medium confirm-address",
            func: openSetCommunityAuthorityModal,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default EditCommunityModal;
