import React from "react";
import styles from "./InputPresentation.module.scss";

interface InputPresentationProps {
  value: string;
  className?: string;
}

const InputPresentation: React.FC<InputPresentationProps> = ({
  value,
  className,
}) => {
  return (
    <>
      <input
        type="text"
        disabled
        value={value}
        className={`${styles.presentation_input} ${
          className ? styles.with_label : ""
        }`}
      />
    </>
  );
};

export default InputPresentation;
