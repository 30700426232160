import { EDIT_CASE, CLEAN_EDIT_CASE } from "../types";

const initialState = {
  id: null,
  caseName: "",
  status: null,
  categories: [],
};

export const editCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CASE:
      return {
        ...state,
        id: action.payload.id,
        caseName: action.payload.caseName,
        status: action.payload.status,
        categories: action.payload.categories,
      };
    case CLEAN_EDIT_CASE:
      return {
        ...state,
        id: null,
        caseName: "",
        status: null,
      };
    default:
      return state;
  }
};
