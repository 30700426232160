import {
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_ADMIN_PANEL,
  HIDE_ADMIN_PANEL,
  SEARCH,
} from "./types";

export function showAdmin() {
  return {
    type: SHOW_ADMIN_PANEL,
  };
}

export function hideAdmin() {
  return {
    type: HIDE_ADMIN_PANEL,
  };
}

export function setSearch(search = "") {
  return {
    type: SEARCH,
    payload: search,
  };
}

export function showLoader() {
  return {
    type: SHOW_LOADER,
  };
}

export function hideLoader() {
  return {
    type: HIDE_LOADER,
  };
}
