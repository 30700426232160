import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Copyright.scss";

const Copyright: React.FC = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState<number>(0);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear);
  }, []);

  return (
    <div className="copyright">
      {t("Shared.Footer.Copyright", { year: year })}
    </div>
  );
};

export default Copyright;
