import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { hideAdmin, showAdmin } from "../../redux/actions";

import "./PageMessage.scss";

interface IPageMessageProps {
  text: string;
  isAdmin?: boolean;
}

const PageMessage: React.FC<IPageMessageProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isAdmin) {
      dispatch(showAdmin());

      return () => {
        dispatch(hideAdmin());
      };
    }
  }, []);

  return (
    <div className="page-message">
      <p>{props.text}</p>
    </div>
  );
};

export default PageMessage;
