import React from "react";

import { ClipLoader } from "react-spinners";

import "./Loader.scss";

interface ILoaderProps {
  loading: boolean;
  className?: string;
}

const Loader: React.FC<ILoaderProps> = ({ loading, className }) => {
  return (
    <div className={`loader ${className ? className : ""}`}>
      <ClipLoader size={40} color={"#FDEE2D"} loading={loading} />
    </div>
  );
};

export default Loader;
