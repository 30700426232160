import { SHOW_ADMIN_PANEL, HIDE_ADMIN_PANEL } from "../types";

const initialState = {
  isAdmin: false,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ADMIN_PANEL:
      return {
        ...state,
        isAdmin: true,
      };
    case HIDE_ADMIN_PANEL:
      return {
        ...state,
        isAdmin: false,
      };
    default:
      return state;
  }
};
