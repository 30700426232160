import React, { useContext, useState, useCallback, createContext } from "react";

import AddAddressModal, {
  IAddAddressModal,
} from "../../../components/modals/AddAddressModal/AddAddressModal";
import EditAddressModal, {
  IEditAddressModal,
} from "../../../components/modals/AddAddressModal/EditAddressModal";
import AddCaseModal, {
  IAddCaseModal,
} from "../../../components/modals/AddCaseModal/AddCaseModal";
import EditCaseModal, {
  IEditCaseModal,
} from "../../../components/modals/AddCaseModal/EditCaseModal";
import EditCommunityModal, {
  IEditCommunityModal,
} from "../../../components/modals/admin/CommunityModal/EditCommunity";
import SetCommunityAuthorityModal, {
  ISetCommunityAuthorityModal,
} from "../../../components/modals/admin/CommunityModal/SetCommunityAuthorityModal";
import AddNetworkModal, {
  IAddNetworkModal,
} from "../../../components/modals/admin/NetworkModal/AddNetworkModal";
import EditNetworkModal, {
  IEditNetworkModal,
} from "../../../components/modals/admin/NetworkModal/EditNetwork";
import AddReporterModal, {
  IAddReporterModal,
} from "../../../components/modals/admin/ReportersModal/AddReporterModal";
import EditReporterModal, {
  IEditReporterModal,
} from "../../../components/modals/admin/ReportersModal/EditReporterModal";
import ConfirmAddressModal, {
  IConfirmAddressModal,
} from "../../../components/modals/ConfirmAddressModal/ConfirmAddressModal";
import ConfirmationModal, {
  IConfirmationModal,
} from "../../../components/modals/ConfirmationModal/ConfirmationMadal";
import { ICommonModalProps } from "../../../components/modals/interfaces";

export enum EModals {
  ADD_ADDRESS_MODAL = "ADD_ADDRESS_MODAL",
  EDIT_ADDRESS_MODAL = "EDIT_ADDRESS_MODAL",
  ADD_CASE_MODAL = "ADD_CASE_MODAL",
  EDIT_CASE_MODAL = "EDIT_CASE_MODAL",
  ADD_NETWORK_MODAL = "ADD_NETWORK_MODAL",
  EDIT_NETWORK_MODAL = "EDIT_NETWORK_MODAL",
  ADD_REPORTER_MODAL = "ADD_REPORTER_MODAL",
  EDIT_REPORTER_MODAL = "EDIT_REPORTER_MODAL",
  EDIT_COMMUNITY_MODAL = "EDIT_COMMUNITY_MODAL",
  SET_COMMUNITY_AUTHORITY_MODAL = "SET_COMMUNITY_AUTHORITY_MODAL",
  CONFIRM_ADDRESS_MODAL = "CONFIRM_ADDRESS_MODAL",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  EMPTY = "EMPTY",
}

type IModalsProps = {
  [EModals.ADD_ADDRESS_MODAL]: IAddAddressModal;
  [EModals.EDIT_ADDRESS_MODAL]: IEditAddressModal;
  [EModals.ADD_CASE_MODAL]: IAddCaseModal;
  [EModals.EDIT_CASE_MODAL]: IEditCaseModal;
  [EModals.ADD_NETWORK_MODAL]: IAddNetworkModal;
  [EModals.EDIT_NETWORK_MODAL]: IEditNetworkModal;
  [EModals.ADD_REPORTER_MODAL]: IAddReporterModal;
  [EModals.EDIT_REPORTER_MODAL]: IEditReporterModal;
  [EModals.EDIT_COMMUNITY_MODAL]: IEditCommunityModal;
  [EModals.SET_COMMUNITY_AUTHORITY_MODAL]: ISetCommunityAuthorityModal;
  [EModals.CONFIRM_ADDRESS_MODAL]: IConfirmAddressModal;
  [EModals.CONFIRMATION_MODAL]: IConfirmationModal;
  [EModals.EMPTY]: any;
};

type IModals = {
  [EModals.EMPTY]: any;
  [EModals.ADD_ADDRESS_MODAL]: React.FC<IAddAddressModal & ICommonModalProps>;
  [EModals.EDIT_ADDRESS_MODAL]: React.FC<IEditAddressModal & ICommonModalProps>;
  [EModals.ADD_CASE_MODAL]: React.FC<IAddCaseModal & ICommonModalProps>;
  [EModals.EDIT_CASE_MODAL]: React.FC<IEditCaseModal & ICommonModalProps>;
  [EModals.ADD_NETWORK_MODAL]: React.FC<IAddNetworkModal & ICommonModalProps>;
  [EModals.EDIT_NETWORK_MODAL]: React.FC<IEditNetworkModal & ICommonModalProps>;
  [EModals.ADD_REPORTER_MODAL]: React.FC<IAddReporterModal & ICommonModalProps>;
  [EModals.EDIT_REPORTER_MODAL]: React.FC<
    IEditReporterModal & ICommonModalProps
  >;
  [EModals.EDIT_COMMUNITY_MODAL]: React.FC<
    IEditCommunityModal & ICommonModalProps
  >;
  [EModals.SET_COMMUNITY_AUTHORITY_MODAL]: React.FC<
    ISetCommunityAuthorityModal & ICommonModalProps
  >;
  [EModals.CONFIRM_ADDRESS_MODAL]: React.FC<
    IConfirmAddressModal & ICommonModalProps
  >;
  [EModals.CONFIRMATION_MODAL]: React.FC<
    IConfirmationModal & ICommonModalProps
  >;
};

const MODALS: IModals = {
  [EModals.ADD_ADDRESS_MODAL]: AddAddressModal,
  [EModals.EDIT_ADDRESS_MODAL]: EditAddressModal,
  [EModals.ADD_CASE_MODAL]: AddCaseModal,
  [EModals.EDIT_CASE_MODAL]: EditCaseModal,
  [EModals.ADD_NETWORK_MODAL]: AddNetworkModal,
  [EModals.EDIT_NETWORK_MODAL]: EditNetworkModal,
  [EModals.ADD_REPORTER_MODAL]: AddReporterModal,
  [EModals.EDIT_REPORTER_MODAL]: EditReporterModal,
  [EModals.EDIT_COMMUNITY_MODAL]: EditCommunityModal,
  [EModals.SET_COMMUNITY_AUTHORITY_MODAL]: SetCommunityAuthorityModal,
  [EModals.CONFIRM_ADDRESS_MODAL]: ConfirmAddressModal,
  [EModals.CONFIRMATION_MODAL]: ConfirmationModal,
  [EModals.EMPTY]: null,
};

interface IInternalProviderState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IInternalProviderState = {
  modal: EModals.EMPTY,
  props: null,
};

interface IContextState {
  modal: EModals;
  props: IModalsProps[EModals];
  showModal: <T extends EModals>(modal: T, props: IModalsProps[T]) => void;
  closeModal: () => void;
}

const initialContextState: IContextState = {
  modal: EModals.EMPTY,
  props: null,
  showModal: (): void => {
    // do nothing
  },
  closeModal: (): void => {
    // do nothing
  },
};

const ModalContext = createContext<IContextState>(initialContextState);
export const useModalContext = (): IContextState => useContext(ModalContext);

export const ModalProvider: React.FC = ({ children }): JSX.Element => {
  const [state, setState] = useState<IInternalProviderState>(initialState);
  const { modal, props } = state;

  const closeModal = useCallback(() => {
    setState(initialState);
  }, []);

  const showModal = <T extends EModals>(modal: T, props: IModalsProps[T]) =>
    setState({ modal, props });

  const Component: IModalsProps[EModals] = MODALS[modal];

  return (
    <ModalContext.Provider value={{ ...state, showModal, closeModal }}>
      {children}

      {Component && <Component closeModal={closeModal} {...props} />}
    </ModalContext.Provider>
  );
};
