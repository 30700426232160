import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";

import { NavLink } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import { Tabs } from "../Tabs/Tabs";
import useWindowSize from "../../hooks/useWindowSize";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { pages } from "../../pages/AdminPanel/AdminPages";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { ReactComponent as HapiLogo } from "../../images/logo/hapi-logo-report.svg";
import { ReactComponent as HapiLogoAdmin } from "../../images/logo/hapi-logo-admin.svg";
import { useSolanaWallet } from "../../shared/providers/solana-wallet-provider";
import AccountDropdown from "../DropdownMenu/AccountDropdown/AccountDropdown";

import "./Header.scss";

const Header: React.FC = () => {
  const isAdmin = useSelector((state: RootState) => state.admin.isAdmin);
  const { width: windowWidth } = useWindowSize();

  const isMobile: boolean = useMemo(() => {
    return windowWidth < 992 ? true : false;
  }, [windowWidth]);

  const { publicKey } = useSolanaWallet();

  return (
    <header className={isAdmin ? "admin-page" : ""}>
      {isAdmin ? (
        <Row middle="xs">
          <Col xs={5} sm={5} md={5} lg={2} xl={2} className="logo">
            <NavLink to="/authority">
              <HapiLogoAdmin />
            </NavLink>
          </Col>
          <Col xs={2} sm={2} md={2} lg={7} xl={7} className="menu">
            {isMobile ? (
              <HamburgerMenu>
                <Tabs pages={pages} vertical />
              </HamburgerMenu>
            ) : (
              <Tabs pages={pages} />
            )}
          </Col>
          <Col xs={5} sm={5} md={5} lg={3} xl={3} className="controls">
            {publicKey ? (
              <DropdownMenu buttonName={publicKey.toString()}>
                <AccountDropdown />
              </DropdownMenu>
            ) : (
              <WalletMultiButton />
            )}
          </Col>
        </Row>
      ) : (
        <Row middle="xs">
          <Col xs={6} sm={6} md={8} lg={9} xl={9} className="logo">
            <NavLink to="/">
              <HapiLogo />
            </NavLink>
          </Col>
          <Col xs={6} sm={6} md={4} lg={3} xl={3} className="controls">
            {publicKey ? (
              <DropdownMenu buttonName={publicKey.toString()}>
                <AccountDropdown />
              </DropdownMenu>
            ) : (
              <WalletMultiButton />
            )}
          </Col>
        </Row>
      )}
    </header>
  );
};

export default Header;
