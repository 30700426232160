import { useState, useEffect } from "react";
import getConfig from "../configs/config";
import { ICasesInterface } from "../shared/interfaces";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchCases = (
  publicKey: any,
  searchString: string,
  skip = 0
) => {
  const config = getConfig();

  const [cases, setCases] = useState<ICasesInterface[]>([]);
  const [casesCount, setCasesCount] = useState<number>(0);

  useEffect(() => {
    const getCases = async () => {
      try {
        let path = "";
        publicKey
          ? (path = `case?community=${config.communityAccount}&skip=${skip}&search=${searchString}`)
          : (path = `case?community=${config.communityAccount}&skip=${skip}&search=${searchString}`);
        const { list, count } = await apiService.get(path);
        setCases(list);
        setCasesCount(count);
      } catch (error) {
        throw new Error(`Error during fetching cases: ${error}`);
      }
    };

    getCases();
  }, [publicKey, searchString, skip]);

  return { cases, casesCount };
};
