import { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { initHapiCore } from "@hapi.one/core-cli";

import { isEditCaseDisable } from "../../shared/helpers/helpers";
import { IReporterInfo } from "../../shared/providers/hapi-service-provider/HapiServiceProvider";

export const useEditCaseDisable = (
  hapiCore: ReturnType<typeof initHapiCore>,
  reporterInfo: IReporterInfo,
  publicKey?: PublicKey | null,
  reporter?: string | PublicKey | null
) => {
  const [isDisableByRole, setDisableByRole] = useState<boolean>(true);

  async function fetchReporterAddress(reporter: string | PublicKey) {
    const _reporter =
      typeof reporter !== "string" ? reporter : new PublicKey(reporter);
    const reporterPubkey = await hapiCore.account.reporter.fetch(_reporter);

    return reporterPubkey.pubkey;
  }

  useEffect(() => {
    async function isEditCaseAvailable(
      reporter: string | PublicKey,
      publicKey: PublicKey
    ) {
      const fetchedReporterAddress = await fetchReporterAddress(reporter);

      const isDisable = isEditCaseDisable(
        reporterInfo.reporterStatus,
        reporterInfo.reporterRole,
        fetchedReporterAddress,
        new PublicKey(publicKey)
      );

      setDisableByRole(isDisable);
    }

    reporter && publicKey && isEditCaseAvailable(reporter, publicKey);
  }, [reporterInfo, reporter]);

  return isDisableByRole;
};
