export default function getConfig(
  env: string | undefined = process.env.REACT_APP_SOLANA_ENV
) {
  switch (env) {
    case "development":
    case "testnet":
      return {
        network: "solana",
        nodeUrl: "https://api.devnet.solana.com",
        explorerUrl: "https://explorer-api.stage.hapi.farm",
        environment: "dev",
        hapiCoreContractId: "hapiAwBQLYRXrjGn6FLCgC8FpQd2yWbKMqS6AYZ48g6",
        communityAccount: "GwUEzE3WYuUcWxPP6S6D5sZB4mJasyE1LAvHtWKy8YPj",
        stakingToken: "Hvixsz7gdtWmdQCT1igQhukVNC6JRDFRujKP5QecyHbG",
      };
    default:
      return {
        network: "solana",
        nodeUrl:
          "https://solana-mainnet.g.alchemy.com/v2/vKewlnCRm00CKQ5itOJl_8oanByvyJS_",
        explorerUrl: "https://explorer-api.hapi.one",
        environment: "main",
        hapiCoreContractId: "hapiAwBQLYRXrjGn6FLCgC8FpQd2yWbKMqS6AYZ48g6",
        communityAccount: "B7JizsfxERnN5s1STwJyJoij68Zab9PQUV57QiYNoYYq",
        stakingToken: "hapiymAn1QKih3vHmqRQyUWLSCbMjRukuRobAQ66Qj6",
      };
  }
}
