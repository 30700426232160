import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import Table, { TR } from "../../../components/Table/Table";
import { EMPTY_TABLE_COL, timeOutTimeAmount } from "../../../shared/constants";
import { hideAdmin, showAdmin } from "../../../redux/actions";
import { ICommunitiesInterface } from "../../../shared/interfaces";
import ToastNotify from "../../../components/Toast/ToastNotify";
import Pagination from "../../../components/Pagination/Pagination";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import SearchNButton from "../../../components/ SearchNButton/SearchNButton";
import {
  EModals,
  useModalContext,
} from "../../../shared/providers/modal-provider/modal-provider";
import { cropString } from "../../../shared/helpers/helpers";
import { useFetchCommunities } from "../../../hooks/useFetchCommunities";
import { useSolanaWallet } from "../../../shared/providers/solana-wallet-provider";
import { useAuthorityContext } from "../../../shared/providers/authority-provider/AuthorityProvider";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./Communities.scss";

const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

const Communities: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { updateCommunity, setCommunityAuthority, getCommunity } =
    useAuthorityContext();
  const { showModal, closeModal } = useModalContext();
  const { publicKey } = useSolanaWallet();
  const { hapiCore, communityAccount } = useHapiServiceContext();

  const table = {
    thead: [
      t("Authority.Communities.Table.Head.Account"),
      t("Authority.Communities.Table.Head.Authority"),
      EMPTY_TABLE_COL,
    ],
  };

  const [tbody, setTbody] = useState<TR[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const [communityAuthority, setCommunityAuthorityState] =
    useState<PublicKey | null>(null);
  const [isCommunityAuthority, setIsCommunityAuthority] =
    useState<boolean>(false);

  const itemsPerPage = 10;
  const skip: number = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;

  const { communities, communitiesCount } = useFetchCommunities(
    skip,
    searchString
  );

  const pages = Math.ceil(communitiesCount / itemsPerPage);

  const updatePaginationHandler = (value: number) => {
    setCurrentPage(value);
  };

  const createTbody = (communities: Array<ICommunitiesInterface>) => {
    const tbodyNetworks = communities.map(
      (community: ICommunitiesInterface) => ({
        account: cropString(community.account, 10),
        authority: cropString(community.authority, 10),
        control: {
          name: "Edit",
          handler: () =>
            openEditCommunityModal(community.account, community.authority),
          disabled: isEditDisable(community.authority),
        },
      })
    );

    setTbody(tbodyNetworks);
  };

  function isEditDisable(itemCommunityAuthority: string) {
    return (
      !isCommunityAuthority ||
      itemCommunityAuthority !== communityAuthority?.toString()
    );
  }

  function openEditCommunityModal(account: string, authority: string): void {
    showModal(EModals.EDIT_COMMUNITY_MODAL, {
      account,
      authority,
      getCommunity,
      handleConfirm: editCommunity,
      setAuthority: setAuthority,
    });
  }

  async function editCommunity(
    stakeUnlockEpochs: string,
    confirmationThreshold: number,
    validatorStake: string,
    tracerStake: string,
    fullStake: string,
    authorityStake: string
  ) {
    try {
      await updateCommunity(
        stakeUnlockEpochs,
        confirmationThreshold,
        validatorStake,
        tracerStake,
        fullStake,
        authorityStake
      );
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddCommunity.Messages.Notifications.CommunityUpdated")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  async function setAuthority(newAuthority: PublicKey) {
    try {
      await setCommunityAuthority(newAuthority);
      closeModal();
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      notify(
        true,
        t(
          "Shared.Modals.SetCommunityAuthority.Messages.Notifications.AuthorityUpdated"
        )
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  useEffect(() => {
    dispatch(showAdmin());

    return () => {
      dispatch(hideAdmin());
    };
  }, []);

  useEffect(() => {
    if (communities.length === 0) return;

    createTbody(communities);
  }, [communities, isCommunityAuthority]);

  useEffect(() => {
    if (!hapiCore) return;

    async function getCommunityInfo() {
      const community = await hapiCore.account.community.fetch(
        communityAccount
      );
      setCommunityAuthorityState(community.authority);
    }
    getCommunityInfo();
  }, [hapiCore]);

  useEffect(() => {
    if (!communityAuthority || !publicKey) return;

    publicKey.equals(communityAuthority) && setIsCommunityAuthority(true);
  }, [communityAuthority, publicKey]);

  return (
    <div className="with-toast-alert">
      <SearchNButton
        search={searchString}
        updateSearch={setSearchString}
        buttonHide={true}
      />
      <Table thead={table.thead} tbody={tbody} />

      {!isNaN(pages) && pages && (
        <Pagination pages={pages} onPageChange={updatePaginationHandler} />
      )}
      <ToastContainer
        autoClose={5000}
        draggable={false}
        closeButton={false}
        position="top-center"
      />
    </div>
  );
};

export default Communities;
