import React from "react";

import { ReactComponent as MediumIcon } from "../../images/social-networks/medium.svg";
import { ReactComponent as TelegramIcon } from "../../images/social-networks/telegram.svg";
import { ReactComponent as TwitterIcon } from "../../images/social-networks/twitter.svg";

import "./SocialNetworks.scss";

interface ISocialNetwork {
  link: string;
  image?: string;
  title?: string;
}

interface ISocialNetworkProps {
  networks: Array<ISocialNetwork>;
}

const SocialNetworks: React.FC<ISocialNetworkProps> = ({ networks }) => {
  const getImage = (imageName: string) => {
    switch (imageName) {
      case "medium":
        return <MediumIcon />;
      case "telegram":
        return <TelegramIcon />;
      case "twitter":
        return <TwitterIcon />;
      default:
        return <MediumIcon />;
    }
  };

  return (
    <div className="social-network right">
      {networks.map((network: ISocialNetwork, index: number) => {
        return (
          <span className="network-wrapper" key={index}>
            <a href={network.link} target="_blank" rel="noreferrer">
              {network.image && (
                <span className="icon">{getImage(network.image)}</span>
              )}
              {network.title && <span className="text">{network.title}</span>}
            </a>
          </span>
        );
      })}
    </div>
  );
};

export default SocialNetworks;
