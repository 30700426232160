import React from "react";
import { useTranslation } from "react-i18next";
import { NetworkSchemaKeys } from "@hapi.one/core-cli";

import Button from "../Button/Button";
import { openConfirmAddressModal } from "../modals/shared";
import AddressInfo from "../Case/Parts/AddressInfo/AddressInfo";
import { useSolanaWallet } from "../../shared/providers/solana-wallet-provider";
import { ReactComponent as AlertIcon } from "../../images/icon-pack/alert.svg";
import { ReactComponent as SuccessIcon } from "../../images/icon-pack/success.svg";
import { useFetchAddressConfirmation } from "../../hooks/useFetchAddressConfirmation";
import { useModalContext } from "../../shared/providers/modal-provider/modal-provider";
import { useConfirmAddressDisable } from "../../hooks/disableButtonHooks/useConfirmAddressDisable";
import { useHapiServiceContext } from "../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./Toast.scss";

interface IAlertToastProps {
  closeToast?(): void;

  risk?: number;
  group?: string;
  message: string;
  success: boolean;
  confirmations?: number;
  address: string;
  networkName: string;
  reporter?: string;
  caseId?: number;
  caseStatus?: string;
  networkSchema: NetworkSchemaKeys;
  addressAccount: string;
}

const AlertToast: React.FC<IAlertToastProps> = ({
  risk,
  group,
  message,
  success,
  confirmations,
  address,
  networkName,
  caseId,
  caseStatus,
  networkSchema,
  reporter,
  addressAccount,
  closeToast,
}) => {
  const { t } = useTranslation();
  const { publicKey } = useSolanaWallet();
  const { hapiCore, reporterInfo } = useHapiServiceContext();
  const { showModal } = useModalContext();

  const addressConfirmationCount =
    reporterInfo.account &&
    useFetchAddressConfirmation(
      addressAccount,
      reporterInfo.account.toString()
    );

  const showAddressInfo =
    (risk || risk === 0) && group && (confirmations || confirmations === 0);

  const isDisableByRole = useConfirmAddressDisable(
    hapiCore,
    reporterInfo,
    addressConfirmationCount || addressConfirmationCount === 0
      ? addressConfirmationCount
      : 1,
    publicKey,
    reporter
  );

  function confirmAddress() {
    if (
      !group ||
      risk === undefined ||
      confirmations === undefined ||
      !closeToast ||
      !caseId
    )
      return;

    closeToast();
    openConfirmAddressModal(
      address,
      risk,
      group,
      networkName,
      confirmations,
      caseId,
      networkSchema,
      showModal
    );
  }

  return (
    <div className={success ? "success-alert" : "fail-alert"}>
      {success ? (
        <>
          <SuccessIcon />
          <p>{message}</p>
          {showAddressInfo && (
            <AddressInfo
              group={group}
              riskScore={risk}
              confirmations={confirmations}
              showConfirmCounter
            />
          )}
        </>
      ) : (
        <>
          <AlertIcon />
          <p>{message}</p>
          {showAddressInfo && (
            <AddressInfo
              group={group}
              riskScore={risk}
              confirmations={confirmations}
              showConfirmCounter
            />
          )}
        </>
      )}
      {!isDisableByRole && caseStatus === "Open" && (
        <Button
          icon="ConfirmIcon"
          name={t("Shared.Modals.ConfirmAddress.Buttons.ConfirmAddress")}
          csName="full-width empty icon-left confirm-address medium"
          clickAction={confirmAddress}
        />
      )}
      <Button name="Ok" csName="full-width outlined" clickAction={closeToast} />
    </div>
  );
};

export default AlertToast;
