import { useState, useEffect } from "react";
import getConfig from "../configs/config";
import { INetworksInterface } from "../shared/interfaces";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchNetworks = (skip = 0) => {
  const config = getConfig();

  const [networks, setNetworks] = useState<INetworksInterface[]>([]);
  const [networksCount, setNetworksCount] = useState<number>(0);

  useEffect(() => {
    const getNetworks = async (skip = 0) => {
      try {
        const path = `network?skip=${skip}&community=${config.communityAccount}`;
        const { list, count } = await apiService.get(path);

        setNetworks(list);
        setNetworksCount(count);
      } catch (error) {
        throw new Error(`Error during fetching networks: ${error}`);
      }
    };

    getNetworks();
  }, [skip]);

  return { networks, networksCount };
};
