import React, { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";

import { ICommonModalProps } from "../../interfaces";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";
import InputMessage from "../../../Form/InputMessage/InputMessage";
import {
  EAddressValidState,
  isValidAddress,
} from "../../../../shared/helpers/helpers";
import { NetworkSchemaEnum } from "../../../../shared/enums";
import InputPresentation from "../../../Form/InputPresentation/InputPresentation";

export interface ISetCommunityAuthorityModal {
  authority: string;
  handleConfirm(newAuthority: PublicKey): void;
}

const SetCommunityAuthorityModal: React.FC<
  ISetCommunityAuthorityModal & ICommonModalProps
> = ({ handleConfirm, closeModal, authority }) => {
  const { t } = useTranslation();

  const [newAuthority, setNewAuthority] = useState<string>("");
  const [addressValid, setAddressValid] = useState<EAddressValidState>(
    EAddressValidState.UNKNOWN
  );

  const closeHandle = () => closeModal();

  const handleNewAuthority = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewAuthority(e.target.value);

  useEffect(() => {
    setAddressValid(isValidAddress(newAuthority, NetworkSchemaEnum.Solana));
  }, [newAuthority]);

  async function setAuthority() {
    try {
      await handleConfirm(new PublicKey(newAuthority));
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  const buttonDisabled =
    !newAuthority || addressValid !== EAddressValidState.ALLOWED;

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.SetCommunityAuthority.Title")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("Shared.Modals.SetCommunityAuthority.Labels.CommunityAuthority")}
            <InputPresentation value={authority} className="with-label" />
          </label>
          <label className="medium">
            {t("Shared.Modals.SetCommunityAuthority.Labels.NewAuthority")}
            <input
              required
              type="text"
              value={newAuthority}
              onInput={handleNewAuthority}
              className="medium"
            />
            {addressValid === EAddressValidState.NOT_ALLOWED && (
              <InputMessage
                message={t(
                  "General.Messages.Hints.IncorrectAddressForNetwork",
                  { network: "SOLANA" }
                )}
                success={false}
              />
            )}
          </label>
        </div>

        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t(
              "Shared.Modals.SetCommunityAuthority.Buttons.SaveAuthority"
            ),
            func: setAuthority,
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default SetCommunityAuthorityModal;
