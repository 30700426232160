import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoadingStatus } from "../../../../images/icon-pack/loading.svg";
import { ReactComponent as CheckedStatus } from "../../../../images/icon-pack/check.svg";

import "./IsOpenStatus.scss";

interface IIsOpenStatusProps {
  isOpen: boolean;
}

const IsOpenStatus: React.FC<IIsOpenStatusProps> = (props) => {
  const { t } = useTranslation();

  return (
    <span className={`is-open-status ${props.isOpen ? "open" : "closed"}`}>
      {props.isOpen ? (
        <>
          <LoadingStatus />
          <span>{t("Shared.Case.Status.Open")}</span>
        </>
      ) : (
        <>
          <CheckedStatus />
          <span>{t("Shared.Case.Status.Closed")}</span>
        </>
      )}
    </span>
  );
};

export default IsOpenStatus;
