import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../Button/Button";
import { ReactComponent as VerifiedIcon } from "../../images/icon-pack/verified.svg";

import styles from "./ConfirmBlock.module.scss";

interface ConfirmBlockProps {
  counter: number;
  isButtonConfirm?: boolean;
  isButtonDisabled?: boolean;
  buttonHandler?: any;
}

const ConfirmBlock: React.FC<ConfirmBlockProps> = ({
  counter,
  isButtonConfirm = false,
  isButtonDisabled = true,
  buttonHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.confirm_block} confirm-block`}>
      <div>
        <VerifiedIcon />
        <span>{counter}</span>
      </div>
      {isButtonConfirm && (
        <Button
          disabled={isButtonDisabled}
          name={t("General.Buttons.ConfirmAddress")}
          clickAction={buttonHandler}
          csName="empty small clear clear-margin"
        />
      )}
    </div>
  );
};

export default ConfirmBlock;
