import React, { useRef } from "react";
import { useOutsideClickCloseHandler } from "../../hooks/useOutsideClickCloseHandler";

interface IOutsideClickWrapper {
  clickHandler(): void;
}

const OutsideClickWrapper: React.FC<IOutsideClickWrapper> = ({
  children,
  clickHandler,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClickCloseHandler(wrapperRef, clickHandler);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickWrapper;
