import {
  CaseStatusKeys,
  NetworkSchemaKeys,
  ReporterRoleKeys,
} from "@hapi.one/core-cli";
export enum EAppEnvironment {
  DEV = "dev",
  MAIN = "main",
  TEST = "test",
}
export interface ICasesInterface {
  name: string;
  account: string;
  reporter: string;
  timeCreated: string;
  timeUpdated?: string;
  communityAccount: string;
  reporterPublicKey: string;
  id: number;
  addressCount: number;
  status: CaseStatusKeys;
}

export interface INetworksInterface {
  account: string;
  communityAccount: string;
  name: string;
  addressTracerReward: string;
  addressConfirmationReward: string;
  assetTracerReward: string;
  assetConfirmationReward: string;
  timeCreated: string;
  timeUpdated: string;
  schema: NetworkSchemaKeys;
}

export interface IReportersInterface {
  name: string;
  role: ReporterRoleKeys;
  pubkey: string;
  account: string;
  isFrozen: boolean;
  timeCreated: string;
  timeUpdated: string;
  communityAccount: string;
}

export interface ICommunitiesInterface {
  account: string;
  authority: string;
  cases: string;
  confirmationThreshold: number;
  stakeUnlockEpochs: string;
  validatorStake: string;
  tracerStake: string;
  fullStake: string;
  authorityStake: string;
  timeCreated: string;
  timeUpdated: string;
}
