import React, { useEffect, useState } from "react";
import {
  CaseStatusKeys,
  CaseStatus,
  CaseStatusVariants,
  stringFromArray,
} from "@hapi.one/core-cli";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import Loader from "../../../components/Loader/Loader";
import TitleClose from "../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../components/Button/TwoButtons/TwoButtons";
import { ICommonModalProps } from "../interfaces";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

export interface IEditCaseModal {
  handleConfirm: any;
  handleEditCaseName: (value: string) => void;
  handleEditCaseStatus: (value: CaseStatusKeys) => void;
  caseId: number;
}

interface CaseData {
  caseName: string;
  caseStatus: CaseStatusKeys;
}

const EditCaseModal: React.FC<IEditCaseModal & ICommonModalProps> = ({
  handleConfirm,
  handleEditCaseName,
  handleEditCaseStatus,
  closeModal,
  caseId,
}) => {
  const { t } = useTranslation();
  const { getCase } = useHapiServiceContext();

  const caseStatuses = CaseStatusVariants;

  const [caseData, setCaseData] = useState<CaseData>({
    caseName: "",
    caseStatus: caseStatuses[0],
  });
  const [caseName, setCaseEditName] = useState<string>(caseData.caseName);
  const [caseStatus, changeStatus] = useState<CaseStatusKeys>(
    caseData.caseStatus
  );
  const [saveButtonDisabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    getCaseData();
  }, []);

  useEffect(() => {
    setCaseEditName(caseData.caseName);
    changeStatus(caseData.caseStatus);
  }, [caseData]);

  useEffect(() => {
    if (caseData.caseStatus !== caseStatus || caseData.caseName !== caseName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [caseStatus, caseName]);

  const handleStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    changeStatus(value as CaseStatusKeys);
  };

  const handleCaseName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaseEditName(e.target.value);
  };

  async function getCaseData() {
    try {
      const data = await getCase(caseId);

      const [[statusConvert]] = Object.entries(CaseStatus).filter((item) => {
        return item.find((f) => {
          return isEqual(data.status, f);
        });
      });

      setCaseData({
        caseName: stringFromArray(data.name),
        caseStatus: statusConvert as CaseStatusKeys,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function handleEditCase() {
    try {
      await handleConfirm(caseName, caseId, CaseStatus[caseStatus]);
      handleEditCaseName(caseName);
      handleEditCaseStatus(caseStatus);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  return (
    <ModalWrapper closeModal={closeModal}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.EditCase.Title")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Input.InputCase")}
            <input
              required
              value={caseName}
              type="text"
              className="medium"
              onChange={handleCaseName}
            />
          </label>
          <label className="medium">
            {t("General.Labels.Select.Status")}
            <select
              value={caseStatus}
              className="medium"
              onChange={handleStatus}
            >
              {caseStatuses.map((_status: CaseStatusKeys) => {
                return (
                  <option key={_status} value={_status}>
                    {_status}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        <TwoButtons
          firstButton={{ name: "Cancel", func: closeModal }}
          secondButton={{
            name: t("Shared.Modals.EditCase.Buttons.SaveCase"),
            func: handleEditCase,
            disabled: saveButtonDisabled,
          }}
        />
      </div>
      {caseId === null && <Loader loading={true} />}
    </ModalWrapper>
  );
};

export default EditCaseModal;
