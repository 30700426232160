import React from "react";

import ReactTooltip from "react-tooltip";
import { toSentenceCase } from "../../shared/helpers/helpers";

import "./StatusDot.scss";

interface IStatusDot {
  status?: string;
}

const StatusDot: React.FC<IStatusDot> = ({ status }) => {
  return (
    <>
      <ReactTooltip />
      <span
        data-tip={status ? toSentenceCase(status) : "Inactive"}
        data-type="light"
        className={`status-dot help ${status}`}
      ></span>
    </>
  );
};

export default StatusDot;
