import React from "react";
import { v4 as uuidv4 } from "uuid";

import DeleteButton from "../Controls/DeleteButton/DeleteButton";
import EditButton from "../Controls/EditButton/EditButton";
import ToggleButton from "../Controls/ToggleButton/ToggleButton";
import StatusDot from "../StatusDot/StatusDot";
import Sticker from "../Sticker/Sticker";

import "./Table.scss";

export interface ITableProps {
  thead: Array<string>;
  tbody: Array<TR>;
}

export interface Role {
  name: string;
}

export interface Control {
  name: string;
  handler: () => void;
}

export interface TR {
  [key: string]: boolean | string | Role | Control;
}

type TD = Array<string | any>;

enum EColorClasses {
  Tracer = "tracer",
  Validator = "validator",
  Publisher = "publisher",
  Authority = "authority",
}

const Table: React.FC<ITableProps> = (props) => {
  const buildTR = (tr: TR) => {
    return Object.entries(tr).map((tdArray: TD) => {
      if (tdArray[0] === "role" && typeof tdArray[1] === "object") {
        return buildTdSticker(tdArray[1].name);
      } else if (tdArray[0] === "control" && typeof tdArray[1] === "object") {
        return buildTdControl(
          tdArray[1].name,
          tdArray[1].handler,
          tdArray[1].disabled
        );
      } else if (tdArray[0] === "isFrozen" && typeof tdArray[1] === "boolean") {
        return buildTdStatus(tdArray[1]);
      } else if (typeof tdArray[1] === "object") {
        return buildTd("Unknown data");
      } else {
        if (tdArray[0] === "name") {
          return buildTd(tdArray[1], "bold align-left");
        }
        return buildTd(tdArray[1]);
      }
    });
  };

  const buildTdSticker = (tdName: string) => {
    return (
      <td key={`${uuidv4()}-${tdName}`} className="control empty">
        <Sticker
          name={tdName}
          clsName={EColorClasses[tdName as keyof typeof EColorClasses]}
        />
      </td>
    );
  };

  const buildTdStatus = (tdName: boolean) => {
    return (
      <td key={`${uuidv4()}-${tdName}`} className="control empty">
        <StatusDot status={tdName ? "frozen" : "active"} />
      </td>
    );
  };

  const buildTdControl = (
    tdName: string,
    handler: () => void,
    disabled?: boolean
  ) => {
    switch (tdName) {
      case "Delete":
        return (
          <td
            key={`${uuidv4()}-${tdName}`}
            className="control delete control-showable"
          >
            <DeleteButton />
          </td>
        );
      case "Toggle":
        return (
          <td key={`${uuidv4()}-${tdName}`} className="control toggle">
            <ToggleButton checked />
          </td>
        );
      case "Edit":
        return (
          <td
            key={`${uuidv4()}-${tdName}`}
            className="control toggle control-showable"
          >
            <EditButton editHandler={handler} disabled={disabled} />
          </td>
        );
      default:
        <td key={`${uuidv4()}-${tdName}`} className="control empty"></td>;
    }
  };

  const buildTd = (tdName: string, className?: string) => {
    return (
      <td
        key={`${uuidv4()}-${tdName}`}
        className={`${className ? className : ""}`}
      >
        {tdName}
      </td>
    );
  };

  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            {props.thead.map((th: string, index: number) => {
              return (
                <th
                  key={`${th}-${uuidv4()}-${index}`}
                  className={`${th === "Name" ? "align-left" : ""}`}
                >
                  {th}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.tbody.map((tr: TR, index: number) => {
            return <tr key={`${index}-${uuidv4()}`}>{buildTR(tr)}</tr>;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
