import { combineReducers } from "redux";
import { adminReducer } from "./adminReducer";
import { appReducer } from "./appReducer";
import { editCaseReducer } from "./editCaseReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  editCase: editCaseReducer,
  admin: adminReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
