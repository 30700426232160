import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { hideAdmin, showAdmin } from "../../../redux/actions";

import "./Home.scss";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showAdmin());

    return () => {
      dispatch(hideAdmin());
    };
  }, []);

  return <></>;
};

export default Home;
