export const links = [
  {
    title: "hapi.one",
    link: "https://hapi.one/",
  },
  {
    title: "How to get whitelisted",
    link: "https://hapi-one.gitbook.io/hapi-protocol/hapi-reporter/how-to-get-whitelisted",
  },
  {
    title: "Reporter staking",
    link: "https://hapi-one.gitbook.io/hapi-protocol/hapi-reporter/reporter-staking",
  },
];
