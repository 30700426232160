import React from "react";

import { ICommonModalProps } from "../interfaces";
import TitleClose from "../Parts/TitleClose/TitleClose";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import TwoButtons from "../../Button/TwoButtons/TwoButtons";

import "./ConfirmationModal.scss";

export interface IConfirmationModal {
  title: string;
  text: string;
  buttonName: string;
  handleConfirm: any;
}

const ConfirmationModal: React.FC<IConfirmationModal & ICommonModalProps> = ({
  handleConfirm,
  title,
  text,
  buttonName,
  closeModal,
}) => {
  const closeHandle = () => {
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <div className="modal-body case-modal confirm-modal">
        <TitleClose
          title={title}
          closeIcon={true}
          clickHandler={() => closeHandle()}
        />
        <div className="text-container">{text}</div>
        <TwoButtons
          firstButton={{ name: "Cancel", func: () => closeHandle() }}
          secondButton={{
            name: buttonName,
            func: handleConfirm,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
