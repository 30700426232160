import getConfig from "../../../configs/config";

class ApiService {
  private baseUrl: string | undefined;
  private networkUrl: string | undefined;

  constructor() {
    this.baseUrl = getConfig().explorerUrl;
    this.networkUrl = getConfig().nodeUrl;
  }

  public async get(path: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}/${path}`);
      return response.json();
    } catch (error) {
      throw new Error(
        `Error during the request to ${this.baseUrl}/${path}: ${error}`
      );
    }
  }

  public async post(body: any): Promise<any> {
    try {
      const response = await fetch(`${this.networkUrl}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      return response.json();
    } catch (error) {
      throw new Error(`Error during the request to ${this.baseUrl}: ${error}`);
    }
  }
}

const apiService = new ApiService();
export default apiService;
