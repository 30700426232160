import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Category, NetworkSchemaKeys } from "@hapi.one/core-cli";

import ToastNotify from "../../Toast/ToastNotify";
import { ICommonModalProps } from "../interfaces";
import TitleClose from "../Parts/TitleClose/TitleClose";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { categoryOptions } from "../../../shared/constants";
import TwoButtons from "../../Button/TwoButtons/TwoButtons";
import { toSentenceCase } from "../../../shared/helpers/helpers";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import InputPresentation from "../../Form/InputPresentation/InputPresentation";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "../AddAddressModal/AddAddressModal.scss";

const risks: Array<number> = Array.from(Array(11).keys());

const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

export interface IEditAddressModal {
  caseId: number;
  hash: string;
  networkName: string;
  networkSchema: NetworkSchemaKeys;
  handleRiskScore: (value: number) => void;
  handleCategory: (value: string) => void;
}

interface AddressData {
  risk: number;
  category: string;
}

const EditAddressModal: React.FC<IEditAddressModal & ICommonModalProps> = ({
  caseId,
  hash: address,
  networkName,
  networkSchema,
  handleRiskScore,
  handleCategory,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { updateAddress, getAddress } = useHapiServiceContext();

  const [addressData, setAddressData] = useState<AddressData>({
    risk: 0,
    category: "",
  });

  const [risk, changeRisk] = useState<number>(addressData.risk);
  const [category, changeCategory] = useState<string>(addressData.category);

  useEffect(() => {
    getAddressData();
  }, []);

  useEffect(() => {
    changeRisk(addressData.risk);
    changeCategory(addressData.category);
  }, [addressData]);

  const handleCategories = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeCategory(e.target.value);
  };

  const handleRisk = (e: React.ChangeEvent<HTMLSelectElement>) =>
    changeRisk(Number(e.target.value));

  const closeHandle = () => {
    closeModal();
  };

  async function getAddressData() {
    const data = await getAddress(address, networkName, networkSchema);

    const [[categoryConvert]] = Object.entries(Category).filter((item) => {
      return item.find((f) => {
        return isEqual(data.category, f);
      });
    });

    setAddressData({
      risk: data.risk,
      category: categoryConvert,
    });
  }

  async function editAddress() {
    try {
      await updateAddress(
        caseId,
        address,
        risk,
        networkName,
        category,
        networkSchema
      );
      closeHandle();
      notify(
        true,
        t("Shared.Modals.EditAddress.Messages.Notifications.AddressUpdated")
      );
      handleRiskScore(risk);
      handleCategory(category);
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  const buttonDisabled =
    addressData.risk === risk && addressData.category === category;

  return (
    <ModalWrapper closeModal={closeModal}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.EditAddress.Title")}
          closeIcon={true}
          clickHandler={() => closeHandle()}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Input.Blockchain")}
            <InputPresentation
              value={toSentenceCase(networkName)}
              className="with-label"
            />
          </label>
          <label className="medium">
            {t("General.Labels.Input.Address")}
            <InputPresentation value={address} className="with-label" />
          </label>

          <label className="medium">
            {t("General.Labels.Select.Category")}
            <select
              value={category}
              className="medium"
              onChange={handleCategories}
            >
              {categoryOptions.map((_category: string) => {
                return (
                  <option key={_category} value={_category}>
                    {_category}
                  </option>
                );
              })}
            </select>
          </label>

          <label className="medium">
            {t("General.Labels.Select.Risk")}
            <select className="medium" value={risk} onChange={handleRisk}>
              {risks.map((risk: number, index) => {
                return (
                  <option
                    key={index}
                    value={risk}
                    defaultValue={addressData.risk}
                  >
                    {risk}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        <TwoButtons
          firstButton={{
            name: t("General.Buttons.Cancel"),
            func: () => closeHandle(),
          }}
          secondButton={{
            name: t("Shared.Modals.EditAddress.Buttons.SaveAddress"),
            func: () => editAddress(),
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default EditAddressModal;
