import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";

import Button from "../../Button/Button";
import Sticker from "../../Sticker/Sticker";
import ToastNotify from "../../Toast/ToastNotify";
import StatusDot from "../../StatusDot/StatusDot";
import MessageToast from "../../MessageToast/MessageToast";
import Balance from "../../../popups/Parts/Balance/Balance";
import { cropString, toSentenceCase } from "../../../shared/helpers/helpers";
import { useSolanaWallet } from "../../../shared/providers/solana-wallet-provider";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";
import { useFetchTokenBalance } from "../../../hooks/useFetchTokenBalance";
import getConfig from "../../../configs/config";
import {
  EModals,
  useModalContext,
} from "../../../shared/providers/modal-provider/modal-provider";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import "./AccountDropdown.scss";

// TODO: extract to utils
const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />, {
    className: "alert-main",
  });
};

const AccountDropdown: React.FC = () => {
  const { t } = useTranslation();

  const [durationToUnstake, setDurationToUnstake] = useState<number>(0);
  const [timerState, setTimerState] = useState<number>(0);

  const { publicKey } = useSolanaWallet();
  const { showModal, closeModal } = useModalContext();
  const {
    reporterInfo,
    getReporterInfo,
    activateReporter,
    deactivateReporter,
    releaseReporter,
    getPossibleTimeToUnstake,
    getUnstakeTimeAmount,
  } = useHapiServiceContext();

  const tokenBalance = useFetchTokenBalance(
    getConfig().explorerUrl,
    publicKey!.toString(),
    getConfig().stakingToken
  );

  useEffect(() => {
    getReporterInfo();
    getUnstakeTimeAmountHandler();
  }, []);

  async function getUnstakeTimeAmountHandler() {
    try {
      const duration = await getUnstakeTimeAmount();
      setDurationToUnstake(duration);
    } catch (error) {
      console.log(error);
    }
  }

  async function activateReporterHandler() {
    try {
      await activateReporter();
      notify(true, t("Shared.Dropdowns.Account.Messages.Activated"));
      getReporterInfo();
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  async function deactivateReporterHandler() {
    try {
      await deactivateReporter();
      notify(true, t("Shared.Dropdowns.Account.Messages.Deactivated"));
      closeModal();
      getReporterInfo();
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  async function releaseReporterHandler() {
    try {
      await releaseReporter();
      notify(true, t("Shared.Dropdowns.Account.Messages.Unstaked"));
      getReporterInfo();
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  async function getPossibleTimeToUnstakeHandle() {
    try {
      const timeToUnstake = await getPossibleTimeToUnstake();
      return timeToUnstake;
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  const deactivateModal = async () => {
    const seconds = await getPossibleTimeToUnstakeHandle();
    const days = seconds ? Math.floor(seconds / (3600 * 24)) : "-";

    showModal(EModals.CONFIRMATION_MODAL, {
      title: t("Shared.Dropdowns.Account.Modals.Deactivate.Title"),
      text: t("Shared.Dropdowns.Account.Modals.Deactivate.Description", {
        days: days,
      }),
      buttonName: t("Shared.Dropdowns.Account.Modals.Deactivate.Button"),
      handleConfirm: deactivateReporterHandler,
    });
  };

  const timerHandler = (value: number) => {
    setTimerState(value);
  };

  return (
    <>
      {publicKey ? (
        <>
          <div className="headline">
            <Button
              csName="empty white medium clear icon-left"
              icon="WalletIcon"
              name={cropString(publicKey.toString(), 4)}
            />
            <div>
              {reporterInfo.reporterStatus ? (
                <StatusDot status={reporterInfo.reporterStatus} />
              ) : (
                <StatusDot />
              )}
              {reporterInfo.reporterRole ? (
                <Sticker
                  clsName={reporterInfo.reporterRole}
                  name={toSentenceCase(reporterInfo.reporterRole)}
                />
              ) : (
                <Sticker
                  clsName={
                    reporterInfo.reporterRole ? reporterInfo.reporterRole : ""
                  }
                  name={t("Shared.ReporterRoles.None")}
                />
              )}
            </div>
          </div>
          <div className="balance-block">
            {tokenBalance && (
              <Balance balance={tokenBalance} currency={"HAPI"} />
            )}
            {reporterInfo.stake && (
              <Button
                csName="small empty grey clear"
                name={`${reporterInfo.stake} HAPI`}
                icon="ArrowRight"
                secondIcon="LockIcon"
              />
            )}
          </div>
          {!reporterInfo.reporterRole && (
            <MessageToast
              message={t(
                "Shared.Dropdowns.Account.Info.ShouldBeWhitelisted.Title"
              )}
              buttonClass="red empty clear"
              buttonName={t(
                "Shared.Dropdowns.Account.Info.ShouldBeWhitelisted.Button"
              )}
              buttonFunc={() =>
                window.open(
                  "https://hapi-one.gitbook.io/hapi-protocol/hapi-reporter/how-to-get-whitelisted/",
                  "_blank"
                )
              }
            />
          )}
          {reporterInfo.reporterRole &&
            reporterInfo.reporterStatus === "active" && (
              <MessageToast
                message={t(
                  "Shared.Dropdowns.Account.Info.PossibleDeactivate.Title",
                  { unstake: reporterInfo.stake }
                )}
                buttonClass="red empty clear"
                buttonName={t(
                  "Shared.Dropdowns.Account.Info.PossibleDeactivate.Button"
                )}
                buttonFunc={deactivateModal}
              />
            )}
          {reporterInfo.reporterRole &&
            reporterInfo.reporterStatus === "inactive" && (
              <MessageToast
                message={t("Shared.Dropdowns.Account.Info.NeedToStake.Title", {
                  stake: reporterInfo.needToStake,
                })}
                buttonClass="empty clear"
                buttonName={t(
                  "Shared.Dropdowns.Account.Info.NeedToStake.Button"
                )}
                buttonFunc={activateReporterHandler}
              />
            )}
          {reporterInfo.reporterRole &&
            reporterInfo.reporterStatus === "unstaking" && (
              <MessageToast
                timeInSeconds={durationToUnstake}
                timerHandler={timerHandler}
                message={t("Shared.Dropdowns.Account.Info.UnstakeAfter.Title", {
                  unstake: reporterInfo.stake,
                })}
                buttonName={t(
                  "Shared.Dropdowns.Account.Info.UnstakeAfter.Button"
                )}
                buttonClass="full-width"
                buttonDisable={timerState > 0}
                buttonFunc={releaseReporterHandler}
              />
            )}
          {reporterInfo.isFrozen && (
            <MessageToast
              message={t("Shared.Dropdowns.Account.Info.Frozen.Title")}
              toastClass="alert"
              buttonName={t("Shared.Dropdowns.Account.Info.Frozen.Button")}
              buttonClass="empty clear grey"
              buttonFunc={() => window.open("https://gov.hapi.one/", "_blank")}
            />
          )}
          <WalletDisconnectButton />
        </>
      ) : (
        <p>{t("Shared.AuthMessages.LoginToManageAccount")}</p>
      )}
    </>
  );
};

export default AccountDropdown;
