import React from "react";

import "./InputMessage.scss";

interface IInputMessageProps {
  message: string;
  success: boolean;
}

const InputMessage: React.FC<IInputMessageProps> = (props) => {
  return (
    <div className={`input-message ${props.success ? "success" : "fail"}`}>
      <span>{props.message}</span>
    </div>
  );
};

export default InputMessage;
