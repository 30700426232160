import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Connection, PublicKey } from "@solana/web3.js";
import {
  bufferFromString,
  Category,
  CategoryKeys,
  initHapiCore,
  NetworkSchemaKeys,
  ReporterRoleKeys,
  ReporterRole,
  ReporterStatusKeys,
  CaseStatus,
} from "@hapi.one/core-cli";
import { Provider, web3, BN } from "@project-serum/anchor";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";

import getConfig from "../../../configs/config";
import { recoverEnum } from "../../helpers/helpers";
import { useSolanaWallet } from "../solana-wallet-provider";
import { useSendTransactionContext } from "../send-transaction-provider/SendTransactionProvider";

interface IHapiServiceContext {
  hapiCore: ReturnType<typeof initHapiCore>;
  connection: Connection;
  communityAccount: PublicKey;
  reporterInfo: IReporterInfo;
  getReporterInfo(): Promise<any>;
  updateCase(
    caseName: string,
    caseId: number,
    status: typeof CaseStatus
  ): Promise<string>;
  addCase(caseName: string): Promise<string>;
  activateReporter(): Promise<string>;
  deactivateReporter(): Promise<string>;
  releaseReporter(): Promise<string>;
  updateAddress(
    caseId: number,
    address: string,
    risk: number,
    networkName: string,
    category: string,
    networkSchema: NetworkSchemaKeys
  ): Promise<string>;
  addAddress(
    caseId: number,
    address: string,
    risk: number,
    networkName: string,
    category: string,
    networkSchema: NetworkSchemaKeys
  ): Promise<string>;
  getAddress(
    address: string,
    network: string,
    networkSchema: NetworkSchemaKeys
  ): Promise<any>;
  getCase(caseId: number): Promise<any>;
  getReporter(): Promise<string>;
  getPossibleTimeToUnstake(): Promise<number>;
  getUnstakeTimeAmount(): Promise<number>;
  confirmAddress(
    networkName: string,
    caseId: number,
    address: string,
    networkSchema: NetworkSchemaKeys
  ): Promise<string>;
}

export interface IReporterInfo {
  account: PublicKey | null;
  reporterRole: ReporterRoleKeys | string | null;
  reporterStatus: ReporterStatusKeys | string | null;
  stake: string | null;
  isFrozen: boolean;
  needToStake: string | null;
}

const initialReporterInfo: IReporterInfo = {
  account: null,
  reporterRole: null,
  reporterStatus: null,
  stake: null,
  isFrozen: false,
  needToStake: null,
};

const config = getConfig();

export const HapiServiceContext = createContext<IHapiServiceContext>(
  {} as IHapiServiceContext
);

export const useHapiServiceContext = () => useContext(HapiServiceContext);

export const HapiServiceProvider: React.FC = ({ children }) => {
  const {
    signTransaction = () => {
      // do nothing
    },
    wallet,
    publicKey,
    connected: isConnected,
  } = useSolanaWallet();

  const [reporterInfo, setReporterInfo] =
    useState<IReporterInfo>(initialReporterInfo);
  const [connection, setConnection] = useState<Connection>(
    new Connection(config.nodeUrl)
  );
  const [provider, setProvider] = useState<Provider | null>(null);
  const [hapiCore, setHapiCore] = useState<any>();
  const [communityAccount, setCommunityAccount] = useState<PublicKey>(
    new web3.PublicKey(config.communityAccount)
  );

  useEffect(() => {
    if (connection && wallet) {
      const _provider = new Provider(connection, wallet as any, {
        commitment: "processed",
      });
      setProvider(_provider);
    } else {
      const _provider = new Provider(connection, null as any, {
        commitment: "processed",
      });
      setProvider(_provider);
    }
  }, [isConnected, connection, wallet]);

  useEffect(() => {
    if (provider && config.hapiCoreContractId) {
      try {
        const hapiCore = initHapiCore(config.hapiCoreContractId, provider);
        hapiCore && setHapiCore(hapiCore);
      } catch (e) {
        console.warn("Error while creating provider", e);
      }
    }
  }, [provider, wallet]);

  async function needToStake(role: string) {
    const { authorityStake, fullStake, tracerStake, validatorStake } =
      await hapiCore.account.community.fetch(communityAccount);

    if (recoverEnum(ReporterRole.Tracer, role)) {
      return tracerStake.toNumber();
    } else if (recoverEnum(ReporterRole.Validator, role)) {
      return validatorStake.toNumber();
    } else if (recoverEnum(ReporterRole.Publisher, role)) {
      return fullStake.toNumber();
    } else if (recoverEnum(ReporterRole.Authority, role)) {
      return authorityStake.toNumber();
    }
  }

  async function getReporterInfo() {
    if (!hapiCore || !publicKey || !wallet) {
      setReporterInfo(initialReporterInfo);
      return;
    }

    try {
      const stakeTokenMint = new web3.PublicKey(config.stakingToken);

      const stakeToken = new Token(
        connection,
        stakeTokenMint,
        TOKEN_PROGRAM_ID,
        (wallet as any)["payer"]
      );

      const { decimals } = await stakeToken.getMintInfo();

      const reporterInfo = hapiCore && publicKey && (await getReporter());
      const _needToStake = await needToStake(Object.keys(reporterInfo.role)[0]);

      if (reporterInfo.role) {
        setReporterInfo({
          account: reporterInfo.account,
          reporterRole: Object.keys(reporterInfo.role)[0],
          reporterStatus: Object.keys(reporterInfo.status)[0],
          stake: (reporterInfo.stake.toNumber() * 10 ** -decimals).toFixed(
            decimals
          ),
          isFrozen: reporterInfo.isFrozen,
          needToStake: (_needToStake * 10 ** -decimals).toFixed(decimals),
        });
      }
    } catch (error) {
      console.log("err", error);
    }
  }

  useEffect(() => {
    getReporterInfo();
  }, [hapiCore, isConnected, publicKey, wallet]);

  const { sendTransaction, endpoint } = useSendTransactionContext();

  const initializeReporterRewardInstruction = async (
    networkAccount: PublicKey,
    reporterAccount: PublicKey,
    reporterRewardAccount: PublicKey,
    bump: number
  ) => {
    if (!hapiCore || !publicKey || !communityAccount || !connection)
      throw new Error("Something went wrong");

    const instruction = await hapiCore.instruction.initializeReporterReward(
      bump,
      {
        accounts: {
          sender: publicKey,
          community: communityAccount,
          network: networkAccount,
          reporter: reporterAccount,
          reporterReward: reporterRewardAccount,
          systemProgram: web3.SystemProgram.programId,
        },
        signers: [],
      }
    );
    return instruction;
  };

  const confirmAddress = async (
    networkName: string,
    caseId: number,
    address: string,
    networkSchema: NetworkSchemaKeys
  ) => {
    const instructions = [];

    const [networkAccount] = await hapiCore.pda.findNetworkAddress(
      communityAccount,
      networkName
    );

    const [reporterAccount] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      publicKey
    );

    const [caseAccount] = await hapiCore.pda.findCaseAddress(
      communityAccount,
      new BN(caseId)
    );

    const addressConvert = hapiCore.util.encodeAddress(address, networkSchema);

    const [addressAccount, bump] = await hapiCore.pda.findAddressAddress(
      networkAccount,
      addressConvert
    );

    const [reporterRewardAccount, bumpRRA] =
      await hapiCore.pda.findReporterRewardAddress(
        networkAccount,
        reporterAccount
      );

    try {
      await hapiCore.account.reporterReward.fetch(reporterRewardAccount);
    } catch (e) {
      instructions.push(
        await initializeReporterRewardInstruction(
          networkAccount,
          reporterAccount,
          reporterRewardAccount,
          bumpRRA
        )
      );
    }

    const addressInfo = await hapiCore.account.address.fetch(addressAccount);

    const [addressReporterRewardAccount] =
      await hapiCore.pda.findReporterRewardAddress(
        networkAccount,
        addressInfo.reporter
      );

    const transaction = await hapiCore.transaction.confirmAddress({
      bump,
      accounts: {
        sender: publicKey,
        community: communityAccount,
        network: networkAccount,
        reporter: reporterAccount,
        reporterReward: reporterRewardAccount,
        addressReporterReward: addressReporterRewardAccount,
        case: caseAccount,
        address: addressAccount,
      },
      signers: [],
      preInstructions: instructions,
    });

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const releaseReporter = async () => {
    if (!publicKey) throw new Error("Something went wrong. Try again later");

    const [reporterAccount] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      publicKey
    );

    const stakeTokenMint = new web3.PublicKey(config.stakingToken);

    const stakeToken = new Token(
      connection,
      stakeTokenMint,
      TOKEN_PROGRAM_ID,
      (wallet as any)["payer"]
    );

    const { address: tokenAccount } =
      await stakeToken.getOrCreateAssociatedAccountInfo(publicKey);

    const communityInfo = await hapiCore.account.community.fetch(
      communityAccount
    );

    const transaction = await hapiCore.transaction.releaseReporter({
      accounts: {
        sender: publicKey,
        community: communityAccount,
        stakeMint: stakeToken.publicKey,
        reporterTokenAccount: tokenAccount,
        communityTokenSigner: communityInfo.tokenSigner,
        communityTokenAccount: communityInfo.tokenAccount,
        tokenProgram: stakeToken.programId,
        reporter: reporterAccount,
      },
      signers: [],
    });

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const activateReporter = async () => {
    const [reporterAccount] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      publicKey
    );

    const community = await hapiCore.account.community.fetch(communityAccount);
    const reporter = await hapiCore.account.reporter.fetch(reporterAccount);

    const stakeTokenMint = new web3.PublicKey(config.stakingToken);

    const stakeToken = new Token(
      connection,
      stakeTokenMint,
      TOKEN_PROGRAM_ID,
      (wallet as any)["payer"]
    );

    const { address: tokenAccount } =
      await stakeToken.getOrCreateAssociatedAccountInfo(publicKey as PublicKey);

    const transaction = await hapiCore.transaction.activateReporter({
      accounts: {
        reporter: reporterAccount,
        sender: publicKey,
        community: communityAccount,
        stakeMint: stakeToken.publicKey,
        reporterTokenAccount: tokenAccount,
        communityTokenAccount: community.tokenAccount,
        tokenProgram: stakeToken.programId,
      },
      signers: [],
    });

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const deactivateReporter = async () => {
    const [reporterAccount] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      publicKey
    );

    const reporter = await hapiCore.account.reporter.fetch(reporterAccount);

    const transaction = await hapiCore.transaction.deactivateReporter({
      accounts: {
        reporter: reporterAccount,
        sender: publicKey,
        community: communityAccount,
      },
      signers: [],
    });

    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    return sendTransaction(signedTransaction);
  };

  const updateCase = useCallback(
    async (caseName: string, caseId: number, status: typeof CaseStatus) => {
      const _caseName = bufferFromString(caseName, 32);

      const [caseAccount] = await hapiCore.pda.findCaseAddress(
        communityAccount,
        new BN(caseId)
      );

      const [reporterAccount] = await hapiCore.pda.findReporterAddress(
        communityAccount,
        publicKey
      );

      const transaction = await hapiCore.transaction.updateCase(
        _caseName.toJSON().data,
        status,
        {
          accounts: {
            reporter: reporterAccount,
            sender: publicKey,
            community: communityAccount,
            case: caseAccount,
          },
          signers: [],
        }
      );

      const { blockhash } = await connection.getRecentBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction(transaction);
      const sendedTransaction = await sendTransaction(signedTransaction);

      return sendedTransaction;
    },
    [isConnected]
  );

  const addCase = useCallback(
    async (caseName: string) => {
      if (!isConnected) throw new Error("Something went wrong"); // TODO: handle error

      const caseName1 = bufferFromString(caseName, 32);

      const community = await hapiCore.account.community.fetch(
        communityAccount
      );

      const caseId = community.cases.addn(1);

      const [caseAccount, bump] = await hapiCore.pda.findCaseAddress(
        communityAccount,
        caseId
      );

      const [reporterAccount] = await hapiCore.pda.findReporterAddress(
        communityAccount,
        publicKey
      );

      const transaction = await hapiCore.transaction.createCase(
        caseId,
        caseName1.toJSON().data,
        bump,
        {
          accounts: {
            reporter: reporterAccount,
            sender: publicKey,
            community: communityAccount,
            case: caseAccount,
            systemProgram: web3.SystemProgram.programId,
          },
          signers: [],
        }
      );

      const { blockhash } = await connection.getRecentBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction(transaction);
      const sendedTransaction = await sendTransaction(signedTransaction);

      return sendedTransaction;
    },
    [isConnected]
  );

  const addAddress = useCallback(
    async (
      caseId: number,
      address: string,
      risk: number,
      networkName: string,
      category: string,
      networkSchema: NetworkSchemaKeys
    ) => {
      if (!isConnected) throw new Error("Something went wrong"); // TODO: handle error
      const instructions = [];

      const [reporterAccount] = await hapiCore.pda.findReporterAddress(
        communityAccount,
        publicKey
      );

      const [networkAccount] = await hapiCore.pda.findNetworkAddress(
        communityAccount,
        networkName
      );

      const addressConvert = hapiCore.util.encodeAddress(
        address,
        networkSchema
      );

      const [addressAccount, bump] = await hapiCore.pda.findAddressAddress(
        networkAccount,
        addressConvert
      );

      const [caseAccount] = await hapiCore.pda.findCaseAddress(
        communityAccount,
        new BN(caseId)
      );

      const categoryConvert = Category[category as CategoryKeys];

      const [reporterRewardAccount, bumpRRA] =
        await hapiCore.pda.findReporterRewardAddress(
          networkAccount,
          reporterAccount
        );

      try {
        await hapiCore.account.reporterReward.fetch(reporterRewardAccount);
      } catch (e) {
        instructions.push(
          await initializeReporterRewardInstruction(
            networkAccount,
            reporterAccount,
            reporterRewardAccount,
            bumpRRA
          )
        );
      }

      const transaction = await hapiCore.transaction.createAddress(
        addressConvert,
        categoryConvert,
        risk,
        bump,
        {
          accounts: {
            sender: publicKey,
            address: addressAccount,
            community: communityAccount,
            network: networkAccount,
            reporter: reporterAccount,
            case: caseAccount,
            systemProgram: web3.SystemProgram.programId,
          },
          signers: [],
          preInstructions: instructions,
        }
      );

      const { blockhash } = await connection.getRecentBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction(transaction);

      return sendTransaction(signedTransaction);
    },
    [isConnected]
  );

  const updateAddress = useCallback(
    async (
      caseId: number,
      address: string,
      risk: number,
      networkName: string,
      category: string,
      networkSchema: NetworkSchemaKeys
    ) => {
      if (!isConnected) throw new Error("Something went wrong");

      const [reporterAccount] = await hapiCore.pda.findReporterAddress(
        communityAccount,
        publicKey
      );

      const [networkAccount] = await hapiCore.pda.findNetworkAddress(
        communityAccount,
        networkName
      );

      const addressConvert = hapiCore.util.encodeAddress(
        address,
        networkSchema
      );

      const [addressAccount] = await hapiCore.pda.findAddressAddress(
        networkAccount,
        addressConvert
      );

      const [caseAccount] = await hapiCore.pda.findCaseAddress(
        communityAccount,
        new BN(caseId)
      );

      const categoryConvert = Category[category as CategoryKeys];

      const transaction = await hapiCore.transaction.updateAddress(
        categoryConvert,
        risk,
        {
          accounts: {
            sender: publicKey,
            address: addressAccount,
            community: communityAccount,
            network: networkAccount,
            reporter: reporterAccount,
            case: caseAccount,
          },
          signers: [],
        }
      );

      const { blockhash } = await connection.getRecentBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      const signedTransaction = await signTransaction(transaction);

      return sendTransaction(signedTransaction);
    },
    [isConnected]
  );

  const getAddress = useCallback(
    async (
      address: string,
      networkName: string,
      networkSchema: NetworkSchemaKeys
    ) => {
      const addressConvert = hapiCore.util.encodeAddress(
        address,
        networkSchema
      );

      const [networkAccount] = await hapiCore.pda.findNetworkAddress(
        communityAccount,
        networkName
      );

      const [addressAddress, ...args] = await hapiCore.pda.findAddressAddress(
        networkAccount,
        addressConvert
      );

      const addressResult = await hapiCore.account.address.fetch(
        addressAddress
      );

      return { ...addressResult, account: addressAddress };
    },
    [hapiCore]
  );

  const getCase = useCallback(
    async (caseId: number) => {
      const [caseAccount] = await hapiCore.pda.findCaseAddress(
        communityAccount,
        new BN(caseId)
      );

      const caseResult = await hapiCore.account.case.fetch(caseAccount);

      return caseResult;
    },
    [hapiCore]
  );

  const getReporter = useCallback(async () => {
    const [reporterAddress, ...args] = await hapiCore.pda.findReporterAddress(
      communityAccount,
      publicKey
    );

    let reporterResult = null;

    try {
      reporterResult = await hapiCore.account.reporter.fetch(reporterAddress);
    } catch (err: any) {
      if (err.message.indexOf("Account does not exist") > -1) {
        reporterResult = null;
      } else {
        throw new Error(
          "Something went wrong during getting info about reporter. Please, try again later."
        );
      }
    }

    return { ...reporterResult, account: reporterAddress };
  }, [hapiCore, publicKey, wallet]);

  const getPossibleTimeToUnstake = async () => {
    if (!hapiCore) throw new Error("Something went wrong");
    const _slotInSec = 0.52;

    const { stakeUnlockEpochs } = await hapiCore.account.community.fetch(
      communityAccount
    );
    const _stakeUnlockEpochs = stakeUnlockEpochs.toString();

    const currentEpoch = await connection.getEpochInfo();

    const toUnstakeInSec = Math.ceil(
      (currentEpoch.slotsInEpoch * _stakeUnlockEpochs -
        currentEpoch.slotIndex) *
        _slotInSec
    );

    return toUnstakeInSec;
  };

  const getUnstakeTimeAmount = async () => {
    const _slotInSec = 0.52;

    const { unlockEpoch } = await getReporter();

    const _unlockEpoch = unlockEpoch.toString();

    const currentEpoch = await connection.getEpochInfo();

    const toUnstakeInSec =
      _unlockEpoch == 0
        ? 0
        : Math.ceil(
            ((_unlockEpoch - currentEpoch.epoch) * currentEpoch.slotsInEpoch -
              currentEpoch.slotIndex) *
              _slotInSec
          );

    return toUnstakeInSec;
  };

  return (
    <HapiServiceContext.Provider
      value={{
        hapiCore,
        connection,
        reporterInfo,
        communityAccount,
        getReporterInfo,
        updateCase,
        addCase,
        activateReporter,
        deactivateReporter,
        releaseReporter,
        updateAddress,
        addAddress,
        getAddress,
        getCase,
        getReporter,
        getPossibleTimeToUnstake,
        getUnstakeTimeAmount,
        confirmAddress,
      }}
    >
      {children}
    </HapiServiceContext.Provider>
  );
};
