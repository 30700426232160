import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";

import Button from "../components/Button/Button";
import PageMessage from "../components/PageMessage/PageMessage";
import { useSolanaWallet } from "../shared/providers/solana-wallet-provider";
import { AuthorityProvider } from "../shared/providers/authority-provider/AuthorityProvider";
import { useHapiServiceContext } from "../shared/providers/hapi-service-provider/HapiServiceProvider";

interface IAuthorityRoute {
  path: string;
  component: any;
  exact?: boolean;
}

const AuthorityRoute: React.FC<IAuthorityRoute> = ({
  component,
  exact,
  ...rest
}) => {
  const { t } = useTranslation();

  const Component = component;
  const history = useHistory();

  const [communityAuthority, setCommunityAuthority] =
    useState<PublicKey | null>(null);
  const [isCommunityAuthority, setIsCommunityAuthority] =
    useState<boolean>(false);

  const { publicKey, connected: isConnected } = useSolanaWallet();
  const { reporterInfo } = useHapiServiceContext();
  const { hapiCore, communityAccount } = useHapiServiceContext();

  function isAuthority(): boolean {
    return reporterInfo.reporterRole === "authority";
  }

  function authMessage(): string {
    if (!isConnected) {
      return t("Shared.AuthMessages.LoginToManageAuthority");
    } else if (isConnected && (!isAuthority() || !isCommunityAuthority)) {
      return t("Shared.AuthMessages.MustBeAuthorityToManage");
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (!hapiCore) return;

    async function getCommunityInfo() {
      const community = await hapiCore.account.community.fetch(
        communityAccount
      );
      setCommunityAuthority(community.authority);
    }
    getCommunityInfo();
  }, [hapiCore]);

  useEffect(() => {
    if (!communityAuthority || !publicKey) return;

    publicKey.equals(communityAuthority) && setIsCommunityAuthority(true);
  }, [communityAuthority, publicKey]);

  return (
    <Route exact {...rest}>
      {isConnected && (isAuthority() || isCommunityAuthority) ? (
        <AuthorityProvider>
          <Component />
        </AuthorityProvider>
      ) : (
        <>
          <PageMessage text={`${authMessage()}`} isAdmin={true} />
          {(!isAuthority() || !isCommunityAuthority) && (
            <Button
              name={t("General.Buttons.BackTo", { route: "reporter page" })}
              csName="medium"
              clickAction={() => history.push("/")}
            />
          )}
        </>
      )}
    </Route>
  );
};

export default AuthorityRoute;
