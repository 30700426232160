import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { CaseStatusKeys } from "@hapi.one/core-cli";
import { useTranslation } from "react-i18next";

import { RootState } from "../../redux/reducers/rootReducer";
import Case from "../../components/Case/Case";
import Loader from "../../components/Loader/Loader";
import ToastNotify from "../../components/Toast/ToastNotify";
import PageMessage from "../../components/PageMessage/PageMessage";
import Pagination from "../../components/Pagination/Pagination";
import SearchNButton from "../../components/ SearchNButton/SearchNButton";
import { useSolanaWallet } from "../../shared/providers/solana-wallet-provider";
import { useFetchCases } from "../../hooks/useFetchCases";
import {
  EModals,
  useModalContext,
} from "../../shared/providers/modal-provider/modal-provider";
import "./ReportAddress.scss";
import { useHapiServiceContext } from "../../shared/providers/hapi-service-provider/HapiServiceProvider";
import { isCaseButtonDisable } from "../../shared/helpers/helpers";
import { errorHandler } from "../../shared/helpers/errorHandler";

interface ICasesInterface {
  name: string;
  account: string;
  reporter: string;
  timeCreated: string;
  timeUpdated?: string;
  communityAccount: string;
  reporterPublicKey: string;
  id: number;
  addressCount: number;
  status: CaseStatusKeys;
}

// TODO: extract to utils
const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

const ReportAddress: React.FC = () => {
  const { t } = useTranslation();
  const { reporterInfo, addCase } = useHapiServiceContext();
  const { showModal, closeModal } = useModalContext();
  const loading = useSelector((state: RootState) => state.app.loading);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const { publicKey, connected } = useSolanaWallet();
  const itemsPerPage = 10;
  const skip: number = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;
  const { cases, casesCount } = useFetchCases(publicKey, searchString, skip);
  const pages = Math.ceil(casesCount / itemsPerPage);

  const [isDisableByRole, setIsDisableByRole] = useState<boolean>(false);

  useEffect(() => {
    const isDisable = isCaseButtonDisable(
      reporterInfo.reporterStatus,
      reporterInfo.reporterRole
    );
    setIsDisableByRole(isDisable);
  }, [reporterInfo]);

  const openAddCaseModal = () => {
    showModal(EModals.ADD_CASE_MODAL, { handleConfirm: sampleCreateCase });
  };

  async function sampleCreateCase(caseName: string) {
    try {
      await addCase(caseName);

      // TODO: find the way, how to do this correctly;
      setTimeout(() => setSearchString(caseName), 4000);
      closeModal();

      notify(true, "Case has been successfully added");
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  const updatePaginationHandler = (value: number) => {
    setCurrentPage(value);
  };

  return (
    <div className="with-toast-alert">
      {publicKey && (
        <SearchNButton
          buttonDisable={isDisableByRole}
          buttonIcon="AddIcon"
          buttonName={t("APP.ReportAddress.Buttons.AddCase")}
          search={searchString}
          updateSearch={setSearchString}
          buttonFunc={openAddCaseModal}
        />
      )}
      {loading && <Loader loading={loading} />}

      {publicKey && cases && cases.length === 0 && !loading && (
        <PageMessage text={t("APP.ReportAddress.Messages.Auth.NoCases")} />
      )}

      {!publicKey && !loading && (
        <PageMessage text={t("APP.ReportAddress.Messages.Auth.HaveToLogin")} />
      )}

      {cases && publicKey && !loading && (
        <div className="cases-wrapper">
          {cases.map((caseItem: ICasesInterface) => {
            return (
              <Case
                addressCount={caseItem.addressCount}
                editCallback={setSearchString}
                id={caseItem.id}
                status={caseItem.status}
                author={""}
                caseName={caseItem.name}
                reporter={caseItem.reporter}
                key={caseItem.id + caseItem.name}
              />
            );
          })}
        </div>
      )}
      {!isNaN(pages) && pages && pages > 1 && publicKey && (
        <Pagination pages={pages} onPageChange={updatePaginationHandler} />
      )}
      <ToastContainer
        autoClose={5000}
        draggable={false}
        closeButton={false}
        position="top-center"
      />
    </div>
  );
};

export default ReportAddress;
