import React, { useContext, createContext } from "react";

type SendTransactionFunction = (...args: any[]) => Promise<string>;

interface ISendTransactionContext {
  sendTransaction: SendTransactionFunction;
  endpoint: string;
}
const SendTransactionContext = createContext<ISendTransactionContext>(
  {} as ISendTransactionContext
);

export const useSendTransactionContext = () =>
  useContext(SendTransactionContext);

export const SendTransactionProvider: React.FC<{
  sendTransaction: SendTransactionFunction;
  endpoint: string;
}> = ({ sendTransaction, endpoint, children }) => {
  return (
    <SendTransactionContext.Provider value={{ sendTransaction, endpoint }}>
      {children}
    </SendTransactionContext.Provider>
  );
};
