import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkSchemaKeys, NetworkSchemaVariants } from "@hapi.one/core-cli";

import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import { ICommonModalProps } from "../../interfaces";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";

export interface IAddNetworkModal {
  handleConfirm(
    networkName: string,
    schema: NetworkSchemaKeys,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ): void;
}

const AddNetworkModal: React.FC<IAddNetworkModal & ICommonModalProps> = ({
  handleConfirm,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [networkName, setNetworkName] = useState<string>("");
  const [addressTracerReward, setAddressTracerReward] = useState<string>("");
  const [addressConfirmationReward, setAddressConfirmationReward] =
    useState<string>("");
  const [assetTracerReward, setAssetTracerReward] = useState<string>("");
  const [assetConfirmationReward, setAssetConfirmationReward] =
    useState<string>("");
  const [currentSchema, changeCurrentNetwork] = useState<NetworkSchemaKeys>(
    Object.values(NetworkSchemaVariants)[0]
  );

  const closeHandle = () => closeModal();

  const handleNetworkName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNetworkName(e.target.value);

  const handleAddressTracerReward = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAddressTracerReward(e.target.value);

  const handleAddressConfirmationReward = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAddressConfirmationReward(e.target.value);

  const handleAssetTracerReward = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAssetTracerReward(e.target.value);

  const handleAssetConfirmationReward = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAssetConfirmationReward(e.target.value);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeCurrentNetwork(e.target.value as NetworkSchemaKeys);
  };

  async function createNetwork() {
    try {
      await handleConfirm(
        networkName,
        currentSchema,
        addressTracerReward,
        addressConfirmationReward,
        assetTracerReward,
        assetConfirmationReward
      );
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  const buttonDisabled =
    !networkName ||
    !currentSchema ||
    !addressTracerReward ||
    !addressConfirmationReward ||
    !assetTracerReward ||
    !assetConfirmationReward;

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddNetwork.Title")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.NetworkName")}
            <input
              required
              type="text"
              value={networkName}
              onInput={handleNetworkName}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.NetworkSchema")}
            <select
              className="medium"
              value={currentSchema}
              onChange={handleSelectChange}
            >
              {NetworkSchemaVariants.map(
                (network: NetworkSchemaKeys, index: number) => {
                  return (
                    <option key={`${index}-${network}`} value={network}>
                      {network}
                    </option>
                  );
                }
              )}
            </select>
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AddressTracerReward")}
            <input
              required
              type="number"
              value={addressTracerReward}
              onInput={handleAddressTracerReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AddressConfirmationReward")}
            <input
              required
              type="number"
              value={addressConfirmationReward}
              onInput={handleAddressConfirmationReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AssetTracerReward")}
            <input
              required
              type="number"
              value={assetTracerReward}
              onInput={handleAssetTracerReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AssetConfirmationReward")}
            <input
              required
              type="number"
              value={assetConfirmationReward}
              onInput={handleAssetConfirmationReward}
              className="medium"
            />
          </label>
        </div>
        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t("Shared.Modals.AddNetwork.Buttons.AddNetwork"),
            func: createNetwork,
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddNetworkModal;
