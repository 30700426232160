import React from "react";

import "./TitleClose.scss";

import { ReactComponent as CloseIcon } from "../../../../images/icon-pack/close-icon.svg";

interface ITitleCloseProps {
  title: string;
  closeIcon?: boolean;
  clickHandler?: () => void;
}

const TitleClose: React.FC<ITitleCloseProps> = (props) => {
  return (
    <div className="title-close">
      <p>{props.title}</p>
      {props.closeIcon && <CloseIcon onClick={props.clickHandler} />}
    </div>
  );
};

export default TitleClose;
