import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PublicKey } from "@solana/web3.js";
import { useTranslation } from "react-i18next";
import { ReporterRole } from "@hapi.one/core-cli";

import { hideAdmin, showAdmin } from "../../../redux/actions";
import Table, { TR } from "../../../components/Table/Table";
import ToastNotify from "../../../components/Toast/ToastNotify";
import Pagination from "../../../components/Pagination/Pagination";
import SearchNButton from "../../../components/ SearchNButton/SearchNButton";
import { toast, ToastContainer } from "react-toastify";
import { IReportersInterface } from "../../../shared/interfaces";
import { useFetchReporters } from "../../../hooks/useFetchReporters";
import {
  EModals,
  useModalContext,
} from "../../../shared/providers/modal-provider/modal-provider";
import { ChangeStatus } from "../../../shared/enums";
import { EMPTY_TABLE_COL, timeOutTimeAmount } from "../../../shared/constants";
import { errorHandler } from "../../../shared/helpers/errorHandler";
import { useSolanaWallet } from "../../../shared/providers/solana-wallet-provider";
import { useAuthorityContext } from "../../../shared/providers/authority-provider/AuthorityProvider";
import { useHapiServiceContext } from "../../../shared/providers/hapi-service-provider/HapiServiceProvider";
import "./Reporters.scss";

// TODO: extract to utils
const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

const Reporters: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const table = {
    thead: [
      EMPTY_TABLE_COL,
      t("Authority.Reporters.Table.Head.Name"),
      t("Authority.Reporters.Table.Head.PublicKey"),
      t("Authority.Reporters.Table.Head.Role"),
      EMPTY_TABLE_COL,
    ],
  };

  const { showModal, closeModal } = useModalContext();
  const { addReporter, updateReporter, getReporterAddress, getReporter } =
    useAuthorityContext();
  const { hapiCore, communityAccount } = useHapiServiceContext();
  const { publicKey } = useSolanaWallet();

  const [searchString, setSearchString] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tbody, setTbody] = useState<TR[]>([]);
  const [communityAuthority, setCommunityAuthority] =
    useState<PublicKey | null>(null);
  const [isCommunityAuthority, setIsCommunityAuthority] =
    useState<boolean>(false);

  const itemsPerPage = 10;
  const skip: number = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;

  const { reporters, reportersCount } = useFetchReporters(skip, searchString);

  const pages: number = Math.ceil(reportersCount / itemsPerPage);

  function updatePaginationHandler(value: number): void {
    setCurrentPage(value);
  }

  function createTbody(reporters: Array<IReportersInterface>): void {
    const tbodyReporters = reporters.map((reporter: IReportersInterface) => ({
      isFrozen: reporter.isFrozen,
      name: reporter.name,
      pubkey: reporter.pubkey,
      role: {
        name: reporter.role,
      },
      control: {
        name: "Edit",
        handler: () => openEditReporterModal(reporter.pubkey),
        disabled: !isCommunityAuthority,
      },
    }));
    setTbody(tbodyReporters);
  }

  async function createReporter(
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string
  ) {
    try {
      await addReporter(role, name, reporterPublicKey);
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddReporter.Messages.Notifications.ReporterAdded")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  function openReporterModal(): void {
    showModal(EModals.ADD_REPORTER_MODAL, {
      handleConfirm: createReporter,
      checkAddressHandler: getReporterAddress,
    });
  }

  async function editReporter(
    role: typeof ReporterRole,
    name: string,
    reporterPublicKey: string,
    freezeReporter: ChangeStatus
  ) {
    try {
      await updateReporter(role, name, reporterPublicKey, freezeReporter);
      setTimeout(() => setSearchString(" "), timeOutTimeAmount);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddReporter.Messages.Notifications.ReporterUpdated")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  }

  function openEditReporterModal(reporterPublicKey: string): void {
    showModal(EModals.EDIT_REPORTER_MODAL, {
      handleConfirm: editReporter,
      getReporter,
      reporterPublicKey,
    });
  }

  useEffect(() => {
    dispatch(showAdmin());

    return () => {
      dispatch(hideAdmin());
    };
  }, []);

  useEffect(() => {
    if (!hapiCore) return;

    async function getCommunityInfo() {
      const community = await hapiCore.account.community.fetch(
        communityAccount
      );
      setCommunityAuthority(community.authority);
    }
    getCommunityInfo();
  }, [hapiCore]);

  useEffect(() => {
    if (reporters.length === 0) return;

    createTbody(reporters);
  }, [reporters, isCommunityAuthority]);

  useEffect(() => {
    if (!communityAuthority || !publicKey) return;

    publicKey.equals(communityAuthority) && setIsCommunityAuthority(true);
  }, [communityAuthority, publicKey]);

  return (
    <div className="with-toast-alert">
      <SearchNButton
        search={searchString}
        updateSearch={setSearchString}
        buttonIcon="AddIcon"
        buttonName={t("Authority.Reporters.Buttons.AddReporter")}
        buttonFunc={openReporterModal}
        buttonDisable={!isCommunityAuthority}
      />
      <Table thead={table.thead} tbody={tbody} />

      {!isNaN(pages) && pages && (
        <Pagination pages={pages} onPageChange={updatePaginationHandler} />
      )}

      <ToastContainer
        autoClose={5000}
        draggable={false}
        closeButton={false}
        position="top-center"
      />
    </div>
  );
};

export default Reporters;
