import React from "react";
import { useTranslation } from "react-i18next";

import "./Balance.scss";

interface IBalanceProps {
  balance: string;
  currency: string;
}

const Balance: React.FC<IBalanceProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="balance">
      <p>{t("General.Components.Balance")}</p>
      <p>
        {props.balance} {props.currency}
      </p>
    </div>
  );
};

export default Balance;
