import React, { useMemo } from "react";
import { clusterApiUrl, Connection, Transaction } from "@solana/web3.js";

import { EAppEnvironment } from "../../interfaces";
import { SendTransactionProvider } from "../send-transaction-provider/SendTransactionProvider";
import {
  getSolanaPhantomWallet,
  getSolanaSolletExtensionWallet,
  getSolanaSolletWallet,
  SolanaConnectionProvider,
  SolanaWalletAdapterNetwork,
  SolanaWalletModalProvider,
  SolanaWalletProvider,
} from "./wallet-adapter";
import getConfig from "../../../configs/config";

const ENVIRONMENT = getConfig().environment as EAppEnvironment;

const SOLANA_ENVIRONMENT: {
  [key in EAppEnvironment]: SolanaWalletAdapterNetwork;
} = {
  [EAppEnvironment.MAIN]: SolanaWalletAdapterNetwork.Mainnet,
  [EAppEnvironment.TEST]: SolanaWalletAdapterNetwork.Testnet,
  [EAppEnvironment.DEV]: SolanaWalletAdapterNetwork.Devnet,
};

export const SolanaProvider: React.FC = ({ children }) => {
  const environment = SOLANA_ENVIRONMENT[ENVIRONMENT];
  const endpoint = useMemo(() => clusterApiUrl(environment), [environment]);

  const wallets = useMemo(
    () => [
      getSolanaPhantomWallet(),
      getSolanaSolletWallet({ network: environment }),
      getSolanaSolletExtensionWallet({ network: environment }),
    ],
    [environment]
  );

  const sendTransaction = async (transaction: Transaction): Promise<string> => {
    try {
      const connection = new Connection(endpoint);

      const transactionSignature = await connection.sendRawTransaction(
        transaction.serialize()
      );

      await connection.getTransaction(transactionSignature, {
        commitment: "finalized",
      });

      return transactionSignature;
    } catch (error) {
      throw new Error(error as string);
    }
  };

  return (
    <SolanaConnectionProvider endpoint={endpoint}>
      <SolanaWalletProvider wallets={wallets} autoConnect={true}>
        <SolanaWalletModalProvider>
          <SendTransactionProvider
            sendTransaction={sendTransaction}
            endpoint={endpoint}
          >
            {children}
          </SendTransactionProvider>
        </SolanaWalletModalProvider>
      </SolanaWalletProvider>
    </SolanaConnectionProvider>
  );
};
