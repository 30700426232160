import { useEffect } from "react";

export const useOutsideClickCloseHandler = (
  ref: React.RefObject<HTMLInputElement>,
  clickHandler: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        event.target instanceof HTMLElement &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        clickHandler();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
