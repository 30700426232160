import React from "react";

import { ReactComponent as ArrowBottom } from "../../../../images/icon-pack/arrow-bottom.svg";

import "./CollapseControl.scss";

interface ICollapseControlProps {
  toggle: boolean;
  clickAction(): void;
}

const CollapseControl: React.FC<ICollapseControlProps> = (props) => {
  return (
    <span
      onClick={props.clickAction}
      className={`collapse-control ${props.toggle ? "open" : "closed"}`}
    >
      <ArrowBottom />
    </span>
  );
};

export default CollapseControl;
