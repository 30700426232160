import React from "react";

import Button from "../Button/Button";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

import "./MessageToast.scss";

interface IMessageToast {
  message: string;
  buttonName: string;
  toastClass?: string;
  buttonClass?: string;
  buttonDisable?: boolean;

  timeInSeconds?: number;

  timerHandler?(value: number): void;
  buttonFunc(): void;
}

const MessageToast: React.FC<IMessageToast> = ({
  message,
  buttonName,
  toastClass,
  buttonClass,
  buttonDisable = false,
  timeInSeconds,
  timerHandler,
  buttonFunc,
}) => {
  return (
    <div className={`message-toast ${toastClass}`}>
      <p>{message}</p>
      {timeInSeconds ? (
        <CountdownTimer
          timerHandler={timerHandler}
          timeInSeconds={timeInSeconds}
        />
      ) : (
        <></>
      )}
      <Button
        disabled={buttonDisable}
        csName={`medium ${buttonClass}`}
        name={buttonName}
        clickAction={buttonFunc}
      />
    </div>
  );
};

export default MessageToast;
