import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CaseStatus, NetworkSchemaKeys } from "@hapi.one/core-cli";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AlertToast from "../../components/Toast/Toast";
import { INetworksInterface } from "../../shared/interfaces";
import {
  EAddressValidState,
  isValidAddress,
  toSentenceCase,
} from "../../shared/helpers/helpers";
import { useFetchNetworks } from "../../hooks/useFetchNetworks";
import { useHapiServiceContext } from "../../shared/providers/hapi-service-provider/HapiServiceProvider";
import "./CheckAddress.scss";
import InputMessage from "../../components/Form/InputMessage/InputMessage";
import { errorHandler } from "../../shared/helpers/errorHandler";

interface ICheckAddressProps {
  location: any;
}

interface ISearchParams {
  address: string;
  blockchain: string;
  schema: string;
}

const CheckAddress: React.FC<ICheckAddressProps> = (props) => {
  const { t } = useTranslation();

  const { getAddress, getCase } = useHapiServiceContext();

  const { networks } = useFetchNetworks();

  const history = useHistory();

  const [inputAddress, setInputAddress] = useState<string>("");

  const [currentNetwork, changeCurrentNetwork] = useState<string>("ethereum"); // TODO: change naming to network

  const [currentSchema, changeCurrentSchema] =
    useState<NetworkSchemaKeys>("Ethereum");

  const [addressValid, setAddressValid] = useState<EAddressValidState>(
    EAddressValidState.UNKNOWN
  );

  const disable: boolean =
    addressValid === EAddressValidState.ALLOWED ? false : true;

  const notify = (
    success: boolean,
    message: string,
    group?: string,
    risk?: number,
    confirmations?: number,
    reporter?: string,
    caseId?: number,
    caseStatusParsed?: string,
    addressAccount?: string
  ) => {
    toast(
      <AlertToast
        success={success}
        message={message}
        group={group}
        risk={risk}
        confirmations={confirmations}
        address={inputAddress}
        networkName={currentNetwork}
        reporter={reporter}
        caseId={caseId}
        caseStatus={caseStatusParsed}
        networkSchema={currentSchema}
        addressAccount={addressAccount ? addressAccount : ""}
      />,
      {
        className: "for-check",
      }
    );
  };

  const checkAddressDefault = (e: React.FormEvent) => {
    e.preventDefault();
    (e as any).target.querySelectorAll("input").forEach((item: HTMLElement) => {
      item.blur();
    });
    getAddressInfo(inputAddress);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputAddress(e.target.value);
  };

  const handleNetworkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeCurrentNetwork(e.target.value);
    changeCurrentSchema(
      e.target[e.target.selectedIndex].getAttribute(
        "data-schema"
      ) as NetworkSchemaKeys
    );
  };

  const getAddressInfo = async (addressInput: string) => {
    try {
      const address = await getAddress(
        addressInput,
        currentNetwork,
        currentSchema
      );

      if (address) {
        const caseId = address.caseId.toNumber();
        const { status } = await getCase(caseId);
        const [caseStatusParsed] = Object.entries(CaseStatus).map(
          ([key, value]) => {
            if (isEqual(value, status)) return key;
          }
        );
        const addressAccount: string = address.account.toString();

        if (address.risk === 0) {
          notify(
            true,
            t("APP.CheckAddress.ResultModal.Risk.Low.Title"),
            toSentenceCase(Object.keys(address.category)[0]),
            address.risk,
            address.confirmations,
            address.reporter,
            caseId,
            caseStatusParsed,
            addressAccount
          );
        } else if (address.risk >= 1 && address.risk <= 5) {
          notify(
            true,
            t("APP.CheckAddress.ResultModal.Risk.Medium.Title"),
            toSentenceCase(Object.keys(address.category)[0]),
            address.risk,
            address.confirmations,
            address.reporter,
            caseId,
            caseStatusParsed,
            addressAccount
          );
        } else {
          notify(
            false,
            t("APP.CheckAddress.ResultModal.Risk.High.Title"),
            toSentenceCase(Object.keys(address.category)[0]),
            address.risk,
            address.confirmations,
            address.reporter,
            caseId,
            caseStatusParsed,
            addressAccount
          );
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  };

  useEffect(() => {
    // For params in URL
    const queryString: any = require("query-string");
    const searchParams: ISearchParams = queryString.parse(
      props.location.search
    );

    if (
      searchParams.address &&
      searchParams.blockchain &&
      searchParams.schema
    ) {
      setInputAddress(searchParams.address);
      changeCurrentNetwork(searchParams.blockchain);
      changeCurrentSchema(searchParams.schema as NetworkSchemaKeys);
    }
  }, []);

  // For adding params in url
  useEffect(() => {
    const params = new URLSearchParams();

    if (inputAddress && currentNetwork) {
      params.append("address", inputAddress);
      params.append("blockchain", currentNetwork);
      params.append("schema", currentSchema);

      history.push({ search: params.toString() });
    } else if (inputAddress.length === 0) {
      params.delete("address");
      params.append("blockchain", currentNetwork);
      params.append("schema", currentSchema);

      history.push({ search: params.toString() });
    }
  }, [inputAddress, currentNetwork, history]);

  useEffect(() => {
    setAddressValid(
      isValidAddress(inputAddress, currentSchema, currentNetwork)
    );
  }, [inputAddress, currentNetwork, currentSchema]);

  return (
    <div className="form-wrapper check-address alert-main">
      <form onSubmit={checkAddressDefault}>
        <div className="two-blocks-inline">
          <div>
            <label>
              {t("General.Labels.Select.SelectBlockchain")}
              <select value={currentNetwork} onChange={handleNetworkChange}>
                {networks.map((network: INetworksInterface, index: number) => {
                  return (
                    <option
                      data-schema={network.schema}
                      key={index}
                      value={network.name}
                    >
                      {network.name.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div>
            <label>
              {t("General.Labels.Input.InputAddress")}
              <input
                required
                value={inputAddress}
                onInput={handleInputChange}
                type="text"
              />
              {addressValid === EAddressValidState.NOT_ALLOWED && (
                <InputMessage
                  message={t(
                    "General.Messages.Hints.IncorrectAddressForNetwork",
                    { network: currentNetwork.toUpperCase() }
                  )}
                  success={false}
                />
              )}
            </label>
          </div>
        </div>
        <input
          type="submit"
          disabled={disable}
          value={`${t("General.Buttons.Check")}`}
        />
      </form>
      <ToastContainer autoClose={false} draggable={false} closeButton={false} />
    </div>
  );
};

export default CheckAddress;
