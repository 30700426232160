import { useState, useEffect } from "react";

import getConfig from "../configs/config";
import { ICommunitiesInterface } from "../shared/interfaces";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchCommunities = (skip = 0, searchString: string) => {
  const [communities, setCommunities] = useState<ICommunitiesInterface[]>([]);
  const [communitiesCount, setCommunitiesCount] = useState<number>(0);

  const config = getConfig();

  useEffect(() => {
    const getCommunities = async () => {
      try {
        const path = `community?community=${config.communityAccount}&skip=${skip}`;
        const { list, count } = await apiService.get(path);

        setCommunities(list);
        setCommunitiesCount(count);
      } catch (error) {
        throw new Error(`Error during fetching communities: ${error}`);
      }
    };

    getCommunities();
  }, [skip, searchString]);

  return { communities, communitiesCount };
};
