export const socialNetworks = [
  {
    image: "telegram",
    link: "https://t.me/hapiHF/",
  },
  {
    image: "medium",
    link: "https://medium.com/i-am-hapi/",
  },
  {
    image: "twitter",
    link: "https://twitter.com/i_am_hapi_one/",
  },
];
