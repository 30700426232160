import { NetworkSchemaKeys } from "@hapi.one/core-cli";
import { EModals } from "../../shared/providers/modal-provider/modal-provider";

export function openConfirmAddressModal(
  hash: string,
  riskScore: number,
  group: string,
  networkName: string,
  confirmations: number,
  caseId: number,
  networkSchema: NetworkSchemaKeys,
  showModal: any,
  handleConfirmations?: (value: number) => void
): void {
  showModal(EModals.CONFIRM_ADDRESS_MODAL, {
    hash,
    riskScore,
    group,
    networkName,
    confirmations,
    caseId,
    networkSchema,
    handleConfirmations,
  });
}

export function openEditAddressModal(
  hash: string,
  riskScore: number,
  group: string,
  networkName: string,
  caseId: number,
  networkSchema: NetworkSchemaKeys,
  handleRiskScore: (value: number) => void,
  handleCategory: (value: string) => void,
  showModal: any
): void {
  showModal(EModals.EDIT_ADDRESS_MODAL, {
    hash,
    riskScore,
    group,
    networkName,
    caseId,
    networkSchema,
    handleRiskScore,
    handleCategory,
  });
}
