import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Row, Col } from "react-flexbox-grid";

import { RootState } from "./redux/reducers/rootReducer";
import { ModalProvider } from "./shared/providers/modal-provider/modal-provider";
import Tabs from "./components/Tabs/Tabs";
import Home from "./pages/AdminPanel/Home/Home";
import Header from "./components/Header/Header";
import CheckAddress from "./pages/CheckAddress/CheckAddress";
import Networks from "./pages/AdminPanel/Networks/Networks";
import Communities from "./pages/AdminPanel/Communities/Communities";
import Reporters from "./pages/AdminPanel/Reporters/Reporters";
import ReportAddress from "./pages/ReportAddress/ReportAddress";
import { SolanaProvider } from "./shared/providers/solana-wallet-provider";
import AuthorityRoute from "./routes/AuthorityRoute";
import { HapiServiceProvider } from "./shared/providers/hapi-service-provider/HapiServiceProvider";
import Footer from "./components/Footer/Footer";

import "./App.scss";
require("@solana/wallet-adapter-react-ui/styles.css");

interface IPage {
  name: string;
  link: string;
}

const App: React.FC = () => {
  const pages: Array<IPage> = [
    {
      name: "Check Address",
      link: "/",
    },
    {
      name: "Report Address",
      link: "/report",
    },
  ];

  const isAdmin = useSelector((state: RootState) => state.admin.isAdmin);

  return (
    <SolanaProvider>
      <HapiServiceProvider>
        <ModalProvider>
          <div className="App">
            <Grid fluid={false}>
              <Header />
              <Row center="xs" className="content">
                <Col xs={12} md={11} lg={8}>
                  {!isAdmin && <Tabs pages={pages} />}
                  <Switch>
                    <Route path={"/"} exact component={CheckAddress} />
                    <Route path={"/report"} component={ReportAddress} />
                    {/* ADMIN */}
                    <AuthorityRoute exact path="/authority" component={Home} />
                    <AuthorityRoute
                      path="/authority/communities"
                      component={Communities}
                    />
                    <AuthorityRoute
                      path="/authority/networks"
                      component={Networks}
                    />
                    <AuthorityRoute
                      path="/authority/reporters"
                      component={Reporters}
                    />
                    {/* END OF ADMIN */}
                  </Switch>
                </Col>
              </Row>
              <Footer />
            </Grid>
          </div>
        </ModalProvider>
      </HapiServiceProvider>
    </SolanaProvider>
  );
};

export default App;
