import React from "react";

import { ReactComponent as WalletIcon } from "../../images/icon-pack/wallet.svg";
import { ReactComponent as PhantomIcon } from "../../images/wallet-icons/phantom.svg";
import { ReactComponent as ArrowRight } from "../../images/icon-pack/arrow-right.svg";
import { ReactComponent as AddIcon } from "../../images/icon-pack/add-icon.svg";
import { ReactComponent as LockIcon } from "../../images/icon-pack/lock-icon.svg";
import { ReactComponent as ConfirmIcon } from "../../images/icon-pack/verified.svg";

import "./Button.scss";

interface IButtonProps {
  name: string;
  icon?: string;
  csName?: string;
  secondIcon?: string;

  disabled?: boolean;

  clickAction?(): void;
}

const Button: React.FC<IButtonProps> = (props) => {
  const renderIcon = (firstIcon: boolean) => {
    switch (props[`${firstIcon ? "icon" : "secondIcon"}`]) {
      case "WalletIcon":
        return <WalletIcon />;
      case "PhantomIcon":
        return <PhantomIcon />;
      case "ArrowRight":
        return <ArrowRight />;
      case "AddIcon":
        return <AddIcon />;
      case "LockIcon":
        return <LockIcon />;
      case "ConfirmIcon":
        return <ConfirmIcon />;
    }
  };

  return (
    <button
      data-testid="button"
      onClick={props.clickAction}
      disabled={props.disabled}
      className={`button ${props.csName && props.csName} ${
        props.icon && "with-icon"
      } ${props.icon && props.secondIcon && "two-icons"}`}
    >
      {props.secondIcon !== null && props.secondIcon !== ""
        ? renderIcon(false)
        : ""}
      <span>{props.name}</span>
      {props.icon !== null && props.icon !== "" ? renderIcon(true) : ""}
    </button>
  );
};

export default Button;
