import { SHOW_LOADER, HIDE_LOADER, SEARCH } from "../types";

const initialState = {
  loading: false,
  search: "",
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    case SEARCH:
      return { ...state, search: action.payload };
    default:
      return state;
  }
};
