import React from "react";

import { ReactComponent as EthereumIcon } from "../../../../images/blockchains/ethereum.svg";
import { ReactComponent as NearIcon } from "../../../../images/blockchains/near.svg";
import { ReactComponent as SolanaIcon } from "../../../../images/blockchains/solana.svg";
import { ReactComponent as BitcoinIcon } from "../../../../images/blockchains/bitcoin.svg";
import { ReactComponent as BSCIcon } from "../../../../images/blockchains/bsc.svg";
import { ReactComponent as OKCIcon } from "../../../../images/blockchains/okc.svg";
import { ReactComponent as TronIcon } from "../../../../images/blockchains/tron.svg";

import "./BlockchainLabel.scss";

interface IBlockchainLabel {
  name: string;
}

const BlockchainLabel: React.FC<IBlockchainLabel> = ({ name }) => {
  const getImage = (imageName: string) => {
    switch (imageName) {
      case "ethereum":
        return <EthereumIcon />;
      case "near":
        return <NearIcon />;
      case "solana":
        return <SolanaIcon />;
      case "bitcoin":
        return <BitcoinIcon />;
      case "bsc":
        return <BSCIcon />;
      case "okc":
        return <OKCIcon />;
      case "tron":
        return <TronIcon />;
      default:
        return <EthereumIcon />;
    }
  };

  return (
    <span className="blockchain-label">
      <span className="icon">{getImage(name)}</span>
      {name}
    </span>
  );
};

export default BlockchainLabel;
