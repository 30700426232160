import React, { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import {
  ReporterRole,
  ReporterRoleKeys,
  ReporterRoleVariants,
} from "@hapi.one/core-cli";
import { useTranslation } from "react-i18next";

import { ICommonModalProps } from "../../interfaces";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";
import InputMessage from "../../../Form/InputMessage/InputMessage";
import {
  EAddressValidState,
  isValidAddress,
} from "../../../../shared/helpers/helpers";
import { AddressCheckState, NetworkSchemaEnum } from "../../../../shared/enums";

export interface IAddReporterModal {
  handleConfirm: any;
  checkAddressHandler: (reporterPubkey: PublicKey) => Promise<any>;
}

const AddReporterModal: React.FC<IAddReporterModal & ICommonModalProps> = ({
  handleConfirm,
  closeModal,
  checkAddressHandler,
}) => {
  const reporterRoles = ReporterRoleVariants;

  const { t } = useTranslation();

  const [reporterName, setReporterName] = useState<string>("");
  const [reporterPubkey, setReporterPubkey] = useState<string>("");
  const [reporterRole, setReporterRole] = useState<ReporterRoleKeys>(
    reporterRoles[0]
  );
  const [addressValid, setAddressValid] = useState<EAddressValidState>(
    EAddressValidState.UNKNOWN
  );
  const [reporterExist, setReporterExist] = useState<AddressCheckState>(
    AddressCheckState.UNKNOWN
  );

  const closeHandle = () => closeModal();

  const handleReporterName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterName(e.target.value);

  const handleReporterPubkey = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterPubkey(e.target.value);

  const handleReporterType = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterRole(e.target.value as ReporterRoleKeys);

  const checkAddressHandle = async () => {
    if (!reporterPubkey) {
      setReporterExist(AddressCheckState.UNKNOWN);
      return;
    }
    try {
      const _reporterPubkey = new PublicKey(reporterPubkey);
      await checkAddressHandler(_reporterPubkey);
      setReporterExist(AddressCheckState.EXIST);
    } catch (error) {
      setReporterExist(AddressCheckState.NOT_EXIST);
    }
  };

  useEffect(() => {
    setAddressValid(isValidAddress(reporterPubkey, NetworkSchemaEnum.Solana));
    checkAddressHandle();
  }, [reporterPubkey]);

  async function createReporter() {
    try {
      await handleConfirm(
        ReporterRole[reporterRole as ReporterRoleKeys],
        reporterName,
        reporterPubkey
      );
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  const buttonDisabled =
    !reporterName ||
    !reporterPubkey ||
    addressValid !== EAddressValidState.ALLOWED ||
    reporterExist === AddressCheckState.EXIST;

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddReporter.Title")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Input.InputReporter")}
            <input
              required
              type="text"
              value={reporterName}
              onInput={handleReporterName}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("General.Labels.Input.InputReporterPubkey")}
            <input
              required
              type="text"
              value={reporterPubkey}
              onInput={handleReporterPubkey}
              className="medium"
            />
            {addressValid === EAddressValidState.NOT_ALLOWED && (
              <InputMessage
                message={t(
                  "General.Messages.Hints.IncorrectAddressForNetwork",
                  { network: "SOLANA" }
                )}
                success={false}
              />
            )}
            {reporterExist === AddressCheckState.EXIST && (
              <InputMessage
                message={t(
                  "Shared.Modals.AddReporter.Messages.Hint.ReporterExist"
                )}
                success={false}
              />
            )}
          </label>

          <div className="radio-group">
            <span className="label">
              {t("General.Labels.Radio.ReporterRole")}
            </span>
            {reporterRoles.map((role: ReporterRoleKeys, index: number) => {
              return (
                <div className="radio-group-item" key={`${index}-${role}`}>
                  <label htmlFor={`radio-${role}`} className="radio">
                    <input
                      type="radio"
                      name="radio-type"
                      value={role}
                      id={`radio-${role}`}
                      defaultChecked={role === reporterRole}
                      onChange={handleReporterType}
                    />
                    <span className="input-label"></span>
                    {role}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t("Shared.Modals.AddReporter.Buttons.AddReporter"),
            func: createReporter,
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddReporterModal;
