import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCollapse from "react-collapsed";
import {
  CaseStatus,
  CaseStatusKeys,
  NetworkSchemaKeys,
} from "@hapi.one/core-cli";
import { toast } from "react-toastify";

import Button from "../Button/Button";
import ToastNotify from "../Toast/ToastNotify";
import IsOpenStatus from "./Parts/IsOpenStatus/IsOpenStatus";
import AddressesList from "./Parts/AddressesList/AddressesList";
import CollapseControl from "./Parts/CollapseControl/CollapseControl";
import {
  EModals,
  useModalContext,
} from "../../shared/providers/modal-provider/modal-provider";
import Pagination from "../Pagination/Pagination";
import { errorHandler } from "../../shared/helpers/errorHandler";
import { isAddAddressButtonDisable } from "../../shared/helpers/helpers";
import { useFetchCaseAddresses } from "../../hooks/useFetchCaseAddresses";
import { useSolanaWallet } from "../../shared/providers/solana-wallet-provider";
import { useEditCaseDisable } from "../../hooks/disableButtonHooks/useEditCaseDisable";
import { useHapiServiceContext } from "../../shared/providers/hapi-service-provider/HapiServiceProvider";

import "./Case.scss";

const notify = (success: boolean, message: string) => {
  toast(<ToastNotify success={success} message={message} />);
};

interface ICaseProps {
  id: number;
  author: string;
  caseName: string;
  status: CaseStatusKeys;
  addressCount: number;
  reporter: string;
  editCallback: (value: string) => void;
}

const Case: React.FC<ICaseProps> = ({
  id: caseId,
  caseName,
  status: caseStatus,
  addressCount,
  reporter,
  editCallback,
}) => {
  const { t } = useTranslation();
  const { publicKey } = useSolanaWallet();
  const { showModal, closeModal } = useModalContext();
  const { reporterInfo, addAddress, getAddress, updateCase, hapiCore } =
    useHapiServiceContext();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const [_caseName, setCaseName] = useState<string>(caseName);
  const [_caseStatus, setCaseStatus] = useState<CaseStatusKeys>(caseStatus);
  const [isDisableByRole, setIsDisableByRole] = useState<boolean>(false);
  const isEditCaseDisableByRole = useEditCaseDisable(
    hapiCore,
    reporterInfo,
    publicKey,
    reporter
  );

  const itemsPerPage = 10;
  const skip: number = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;
  const { addresses, isLoading, addressesCount } = useFetchCaseAddresses(
    isExpanded,
    caseId,
    skip
  );

  const { getCollapseProps } = useCollapse({
    isExpanded: isExpanded && !isLoading,
  });
  const pages = Math.ceil(addressesCount / itemsPerPage);

  useEffect(() => {
    const isDisable = isAddAddressButtonDisable(
      reporterInfo.reporterStatus,
      reporterInfo.reporterRole
    );
    setIsDisableByRole(isDisable);
  }, [reporterInfo]);

  const submitAddAddress = async (
    address: string,
    risk: number,
    networkName: string,
    category: string,
    networkSchema: NetworkSchemaKeys
  ) => {
    try {
      await addAddress(
        caseId,
        address,
        risk,
        networkName,
        category,
        networkSchema
      );
      setTimeout(() => {
        editCallback(address);
      }, 3000);
      closeModal();
      notify(
        true,
        t("Shared.Modals.AddAddress.Messages.Notifications.AddressAdded")
      );
    } catch (error) {
      if (error instanceof Error) {
        notify(false, errorHandler(error.message));
      }
    }
  };

  const submitEditCase = async (
    caseName: string,
    caseId: number,
    status: typeof CaseStatus
  ) => {
    try {
      await updateCase(caseName, caseId, status);
      closeModal();
      notify(
        true,
        t("Shared.Modals.EditCase.Messages.Notifications.CaseUpdated")
      );
    } catch (error) {
      closeModal();
      notify(false, t("General.Messages.Errors.SomethingWentWrong"));
    }
  };

  const openAddAddressModal = () => {
    showModal(EModals.ADD_ADDRESS_MODAL, {
      handleConfirm: submitAddAddress,
      checkAddress: getAddress,
    });
  };

  const openEditCaseModal = () => {
    showModal(EModals.EDIT_CASE_MODAL, {
      handleConfirm: submitEditCase,
      handleEditCaseName,
      handleEditCaseStatus,
      caseId,
    });
  };

  const handleEditCaseName = (value: string) => {
    setCaseName(value);
  };

  const handleEditCaseStatus = (value: CaseStatusKeys) => {
    setCaseStatus(value);
  };

  const updatePaginationHandler = (value: number) => {
    setCurrentPage(value);
  };

  return (
    <div className="case">
      <div className="name-status-collapse">
        <span
          className={`name ${isEditCaseDisableByRole ? "disable" : "enable"}`}
          onClick={isEditCaseDisableByRole ? () => void 0 : openEditCaseModal}
        >
          {_caseName}
        </span>
        <IsOpenStatus isOpen={_caseStatus === "Open"} />
        <CollapseControl
          toggle={isExpanded}
          clickAction={() => setExpanded((prev) => !prev)}
        />
      </div>

      <div className="additional-info">
        <span>{caseId}</span>
        {(addressCount || addressCount === 0) && (
          <span>
            {addressCount === 1
              ? t("Shared.Case.Address.Single", { count: addressCount })
              : t("Shared.Case.Address.Plural", { count: addressCount })}
          </span>
        )}
      </div>

      <div className="case-content" {...getCollapseProps()}>
        {isExpanded && (
          <div className="case-content-items">
            <AddressesList caseStatus={_caseStatus} addresses={addresses} />
            {!isNaN(pages) && pages && pages > 1 && publicKey ? (
              <Pagination
                pages={pages}
                onPageChange={updatePaginationHandler}
              />
            ) : (
              <></>
            )}
            <Button
              disabled={
                isDisableByRole || _caseStatus === Object.keys(CaseStatus)[0]
              }
              icon="AddIcon"
              name={t("General.Buttons.AddAddress")}
              csName="medium icon-left empty"
              clickAction={openAddAddressModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Case);
