import { useState, useEffect } from "react";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchAddressConfirmation = (
  account: string,
  reporter: string
) => {
  const [confirmationCount, setConfirmationCount] = useState<number>(0);

  useEffect(() => {
    if (!account || !reporter) return;

    const getAddressConfirmation = async () => {
      try {
        const path = `address/${account}/checkConfirmation/${reporter}`;
        const result = await apiService.get(path);

        setConfirmationCount(result.count);
      } catch (error) {
        throw new Error(`Error during fetching address confirmation: ${error}`);
      }
    };

    getAddressConfirmation();
  }, [account, reporter]);

  return confirmationCount;
};
