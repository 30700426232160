import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ReporterRole,
  ReporterRoleKeys,
  ReporterRoleVariants,
  stringFromArray,
} from "@hapi.one/core-cli";
import { isEqual } from "lodash";

import { ICommonModalProps } from "../../interfaces";
import { ChangeStatus } from "../../../../shared/enums";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";
import ToggleButton from "../../../Controls/ToggleButton/ToggleButton";

export interface IEditReporterModal {
  reporterPublicKey: string;
  handleConfirm: any;
  getReporter: any;
}

interface ReporterData {
  name: string;
  role: ReporterRoleKeys;
  isFrozen: boolean;
}

const EditReporterModal: React.FC<IEditReporterModal & ICommonModalProps> = ({
  reporterPublicKey,
  handleConfirm,
  getReporter,
  closeModal,
}) => {
  const reporterRoles = ReporterRoleVariants;
  const { t } = useTranslation();

  const [reporterData, setReporterData] = useState<ReporterData>({
    name: "",
    role: ReporterRoleVariants[0],
    isFrozen: false,
  });

  const [reporterName, setReporterName] = useState<string>(reporterData.name);
  const [reporterPubkey, setReporterPubkey] =
    useState<string>(reporterPublicKey);
  const [reporterRole, setReporterType] = useState<ReporterRoleKeys>(
    reporterData.role
  );
  const [freezeReporterStatus, setFreezeReporterStatus] =
    useState<ChangeStatus>(ChangeStatus.UNCHANGED);

  const closeHandle = () => closeModal();
  const handleReporterName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterName(e.target.value);
  const handleReporterPubkey = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterPubkey(e.target.value);
  const handleReporterType = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReporterType(e.target.value as ReporterRoleKeys);
  const handleFreezeReporterStatus = (status: ChangeStatus) => {
    setFreezeReporterStatus(status);
  };

  const buttonDisabled: boolean =
    (reporterName === reporterData.name &&
      reporterRole === reporterData.role &&
      freezeReporterStatus === ChangeStatus.UNCHANGED) ||
    !reporterName;

  useEffect(() => {
    getReporterData();
  }, []);

  useEffect(() => {
    setReporterName(reporterData.name);
    setReporterType(reporterData.role);
  }, [reporterData]);

  async function getReporterData() {
    try {
      const data = await getReporter(reporterPublicKey);

      const [[roleConvert]] = Object.entries(ReporterRole).filter((item) => {
        return item.find((f) => {
          return isEqual(data.role, f);
        });
      });

      setReporterData({
        name: data.name,
        role: roleConvert as ReporterRoleKeys,
        isFrozen: data.isFrozen,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function updateReporter() {
    try {
      await handleConfirm(
        ReporterRole[reporterRole as ReporterRoleKeys],
        reporterName,
        reporterPubkey,
        freezeReporterStatus
      );
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddReporter.EditTitle")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Input.InputReporter")}
            <input
              required
              type="text"
              value={reporterName}
              onInput={handleReporterName}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("General.Labels.Input.InputReporterPubkey")}
            <input
              disabled
              type="text"
              value={reporterPubkey}
              onInput={handleReporterPubkey}
              className="medium"
            />
          </label>

          <div className="radio-group">
            <span className="label">
              {t("General.Labels.Radio.ReporterRole")}
            </span>
            {reporterRoles.map((role: ReporterRoleKeys, index: number) => {
              return (
                <div className="radio-group-item" key={`${role}-${index}`}>
                  <label htmlFor={`radio-${role}`} className="radio">
                    <input
                      type="radio"
                      name="radio-type"
                      value={role}
                      id={`radio-${role}`}
                      checked={role === reporterRole}
                      onChange={handleReporterType}
                    />
                    <span className="input-label"></span>
                    {role}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="toggle-wrapper">
            <ToggleButton
              text={t("Shared.Modals.AddReporter.Buttons.FreezeReporter")}
              checked={reporterData.isFrozen}
              handler={handleFreezeReporterStatus}
            />
          </div>
        </div>

        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t("Shared.Modals.AddReporter.Buttons.SaveReporter"),
            func: updateReporter,
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default EditReporterModal;
