import { useState, useEffect } from "react";

import getConfig from "../configs/config";
import { IReportersInterface } from "../shared/interfaces";
import apiService from "../shared/services/api-service.ts/api-service";

export const useFetchReporters = (skip = 0, searchString: string) => {
  const [reporters, setReporters] = useState<IReportersInterface[]>([]);
  const [reportersCount, setReportersCount] = useState<number>(0);

  const config = getConfig();

  useEffect(() => {
    const getReporters = async () => {
      try {
        const path = `reporter?community=${config.communityAccount}&skip=${skip}`;
        const { list, count } = await apiService.get(path);

        setReporters(list);
        setReportersCount(count);
      } catch (error) {
        throw new Error(`Error during fetching reporters: ${error}`);
      }
    };

    getReporters();
  }, [skip, searchString]);

  return { reporters, reportersCount };
};
