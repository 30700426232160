import React from "react";

import Button from "../Button";

import "./TwoButtons.scss";

interface SingleButton {
  name: string;
  csName?: string;
  disabled?: boolean;
  func: () => void;
}

interface ITwoButtonsProps {
  firstButton: SingleButton;
  secondButton: SingleButton;
  additionalButton?: SingleButton;
}

const TwoButtons: React.FC<ITwoButtonsProps> = (props) => {
  return (
    <div
      className={`two-buttons ${
        props.additionalButton ? "with-additional" : ""
      }`}
    >
      {props.additionalButton && (
        <div className="additional-button">
          <Button
            csName={`${props.additionalButton.csName}`}
            name={props.additionalButton.name}
            clickAction={props.additionalButton.func}
          />
        </div>
      )}
      <Button
        csName="medium outlined grey-hapi-1"
        name={props.firstButton.name}
        clickAction={props.firstButton.func}
        disabled={props.firstButton.disabled}
      />
      <Button
        csName="medium"
        name={props.secondButton.name}
        clickAction={props.secondButton.func}
        disabled={props.secondButton.disabled}
      />
    </div>
  );
};

export default TwoButtons;
