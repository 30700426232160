import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkSchemaKeys } from "@hapi.one/core-cli";

import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import { ICommonModalProps } from "../../interfaces";
import TitleClose from "../../Parts/TitleClose/TitleClose";
import TwoButtons from "../../../Button/TwoButtons/TwoButtons";
import InputPresentation from "../../../Form/InputPresentation/InputPresentation";

export interface IEditNetworkModal {
  networkName: string;
  currentSchema: NetworkSchemaKeys;
  getNetwork: (name: string) => Promise<any>;
  handleConfirm(
    networkName: string,
    addressTracerReward: string,
    addressConfirmationReward: string,
    assetTracerReward: string,
    assetConfirmationReward: string
  ): void;
}

interface NetworkData {
  addressTracerReward: string;
  addressConfirmationReward: string;
  assetTracerReward: string;
  assetConfirmationReward: string;
}

const EditNetworkModal: React.FC<IEditNetworkModal & ICommonModalProps> = ({
  networkName,
  currentSchema,
  getNetwork,
  handleConfirm,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [networkData, setNetworkData] = useState<NetworkData>({
    addressTracerReward: "",
    addressConfirmationReward: "",
    assetTracerReward: "",
    assetConfirmationReward: "",
  });

  const [addressTracerReward, setAddressTracerReward] = useState<string>(
    networkData.addressTracerReward
  );
  const [addressConfirmationReward, setAddressConfirmationReward] =
    useState<string>(networkData.addressConfirmationReward);
  const [assetTracerReward, setAssetTracerReward] = useState<string>(
    networkData.assetTracerReward
  );
  const [assetConfirmationReward, setAssetConfirmationReward] =
    useState<string>(networkData.assetConfirmationReward);

  const closeHandle = () => closeModal();

  const handleAddressTracerReward = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAddressTracerReward(e.target.value);

  const handleAddressConfirmationReward = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAddressConfirmationReward(e.target.value);

  const handleAssetTracerReward = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAssetTracerReward(e.target.value);

  const handleAssetConfirmationReward = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAssetConfirmationReward(e.target.value);

  useEffect(() => {
    getNetworkData();
  }, []);

  useEffect(() => {
    setAddressTracerReward(networkData.addressTracerReward);
    setAddressConfirmationReward(networkData.addressConfirmationReward);
    setAssetTracerReward(networkData.assetTracerReward);
    setAssetConfirmationReward(networkData.assetConfirmationReward);
  }, [networkData]);

  async function getNetworkData() {
    try {
      const data = await getNetwork(networkName);
      setNetworkData({
        addressTracerReward: data.addressTracerReward.toString(),
        addressConfirmationReward: data.addressConfirmationReward.toString(),
        assetTracerReward: data.assetTracerReward.toString(),
        assetConfirmationReward: data.assetConfirmationReward.toString(),
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function editNetwork() {
    try {
      await handleConfirm(
        networkName,
        addressTracerReward,
        addressConfirmationReward,
        assetTracerReward,
        assetConfirmationReward
      );
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  const buttonDisabled =
    !addressTracerReward ||
    !addressConfirmationReward ||
    !assetTracerReward ||
    !assetConfirmationReward ||
    (networkData.addressTracerReward === addressTracerReward &&
      networkData.addressConfirmationReward === addressConfirmationReward &&
      networkData.assetTracerReward === assetTracerReward &&
      networkData.assetConfirmationReward === assetConfirmationReward);

  return (
    <ModalWrapper closeModal={closeHandle}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddNetwork.EditTitle")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.NetworkName")}
            <InputPresentation value={networkName} className="with-label" />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.NetworkSchema")}
            <InputPresentation value={currentSchema} className="with-label" />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AddressTracerReward")}
            <input
              required
              type="number"
              value={addressTracerReward}
              onInput={handleAddressTracerReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AddressConfirmationReward")}
            <input
              required
              type="number"
              value={addressConfirmationReward}
              onInput={handleAddressConfirmationReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AssetTracerReward")}
            <input
              required
              type="number"
              value={assetTracerReward}
              onInput={handleAssetTracerReward}
              className="medium"
            />
          </label>

          <label className="medium">
            {t("Shared.Modals.AddNetwork.Labels.AssetConfirmationReward")}
            <input
              required
              type="number"
              value={assetConfirmationReward}
              onInput={handleAssetConfirmationReward}
              className="medium"
            />
          </label>
        </div>
        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeHandle }}
          secondButton={{
            name: t("Shared.Modals.AddNetwork.Buttons.SaveNetwork"),
            func: editNetwork,
            disabled: buttonDisabled,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default EditNetworkModal;
