import React, { useState } from "react";
import "./HamburgerMenu.scss";

const HamburgerMenu: React.FC = ({ children }) => {
  const [click, setClick] = useState<boolean>(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="hamburger-menu">
      <label className="menu-label" htmlFor="navi-toggle" onClick={handleClick}>
        <span className={`${click ? "clicked" : ""} hamburger-icon`}></span>
      </label>

      <div className={`${click ? "clicked" : ""} nav-background`}>&nbsp;</div>
      <div
        className={`${click ? "clicked" : ""} hamburger-navigation`}
        onClick={handleClick}
      >
        {children}
      </div>
    </div>
  );
};

export default HamburgerMenu;
