import React from "react";

import "./EditButton.scss";

import { ReactComponent as EditIcon } from "../../../images/icon-pack/edit-icon.svg";

interface IEditButtonInterface {
  disabled?: boolean;
  editHandler: () => void;
}

const EditButton: React.FC<IEditButtonInterface> = (props) => {
  const { editHandler } = props;

  return (
    <button
      onClick={editHandler}
      className="edit-button"
      disabled={props.disabled ? true : false}
    >
      <EditIcon />
    </button>
  );
};

export default EditButton;
