import React from "react";

import { NavLink } from "react-router-dom";

import "./Tabs.scss";

interface IPage {
  link: string;
  name: string;
}

interface ITabsProps {
  pages: Array<IPage>;
  vertical?: boolean;
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  return (
    <div data-testid="tabs" className="tabs-wrapper">
      <ul className={`${props.vertical ? "vertical-menu" : "horizontal-menu"}`}>
        {props.pages.map((page, index) => {
          return (
            <li key={index}>
              <NavLink
                exact={page.link === "/" ? true : false}
                to={page.link}
                activeClassName="current"
              >
                {page.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
