import React from "react";

import "./Sticker.scss";

interface IStickerProps {
  name: string;
  clsName?: string;
}

const Sticker: React.FC<IStickerProps> = (props) => {
  return (
    <span className={`sticker ${props.clsName ? props.clsName : ""}`}>
      {props.name}
    </span>
  );
};

export default Sticker;
