import React, { useEffect, useState } from "react";

import "./CountdownTimer.scss";

interface ICountdownTimerProps {
  timeInSeconds: number;
  timerHandler?(value: number): void;
}

const CountdownTimer: React.FC<ICountdownTimerProps> = ({
  timeInSeconds,
  timerHandler,
}) => {
  timeInSeconds = timeInSeconds < 0 ? 0 : timeInSeconds;
  const toDays = (initialTime: number): string => {
    return (
      (Math.floor(initialTime / (3600 * 24)) < 10 ? "0" : "") +
      Math.floor(initialTime / (3600 * 24))
    );
  };

  const toHours = (initialTime: number): string => {
    return (
      (Math.floor((initialTime % (3600 * 24)) / 3600) < 10 ? "0" : "") +
      Math.floor((initialTime % (3600 * 24)) / 3600)
    );
  };

  const toMinutes = (initialTime: number): string => {
    return (
      (Math.floor((initialTime % 3600) / 60) < 10 ? "0" : "") +
      Math.floor((initialTime % 3600) / 60)
    );
  };

  const toSeconds = (initialTime: number): string => {
    return (
      (Math.floor(initialTime % 60) < 10 ? "0" : "") +
      Math.floor(initialTime % 60)
    );
  };

  const d = toDays(timeInSeconds);
  const h = toHours(timeInSeconds);
  const m = toMinutes(timeInSeconds);
  const s = toSeconds(timeInSeconds);

  const [durationInSec, setDurationInSec] = useState<number>(timeInSeconds);

  const [[days, hrs, mins, secs], setTime] = React.useState([d, h, m, s]);

  useEffect(() => {
    if (durationInSec < 0) return;

    const interval = setInterval(() => {
      setDurationInSec(durationInSec - 1);
      timerHandler && timerHandler(durationInSec - 1);
      setTime([
        toDays(durationInSec),
        toHours(durationInSec),
        toMinutes(durationInSec),
        toSeconds(durationInSec),
      ]);
    }, 1000);
    return () => clearInterval(interval);
  }, [durationInSec]);

  return (
    <div className="countdown-timer">
      <span>
        <span>{days}</span>
        <span>d</span>
      </span>
      :
      <span>
        <span>{hrs}</span>
        <span>h</span>
      </span>
      :
      <span>
        <span>{mins}</span>
        <span>m</span>
      </span>
      :
      <span>
        <span>{secs}</span>
        <span>s</span>
      </span>
    </div>
  );
};

export default CountdownTimer;
