import i18n from "../i18n/i18n";

export const errorHandler = (errorMessage: string): string => {
  if (
    errorMessage.indexOf(
      "Attempt to debit an account but found no record of a prior credit."
    ) > -1
  ) {
    return i18n.t("General.Messages.Errors.ZeroBalanceOfSolana");
  } else if (
    errorMessage.match(/\b0x1\b/gi) ||
    errorMessage.indexOf("Failed to find account") > -1
  ) {
    return i18n.t("General.Messages.Errors.ZeroBalanceOfHapi");
  } else if (
    errorMessage.indexOf("Invalid public key input") > -1 ||
    errorMessage.indexOf("Account does not exist") > -1
  ) {
    return i18n.t("General.Messages.Errors.NoInformation");
  } else if (errorMessage.match(/\b0x1778\b/gi)) {
    return i18n.t("General.Messages.Errors.CaseClosed");
  } else {
    return i18n.t("General.Messages.Errors.SomethingWentWrong");
  }
};
