import React, { useState } from "react";

import { ICommonModalProps } from "../interfaces";
import TitleClose from "../Parts/TitleClose/TitleClose";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import TwoButtons from "../../../components/Button/TwoButtons/TwoButtons";

import "../Modal.scss";
import "./AddCaseModal.scss";
import { useTranslation } from "react-i18next";

export interface IAddCaseModal {
  handleConfirm: any;
}

const AddCaseModal: React.FC<IAddCaseModal & ICommonModalProps> = ({
  closeModal,
  handleConfirm,
}) => {
  const { t } = useTranslation();
  const [caseName, setCaseName] = useState<string>("");

  const handleCaseName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaseName(e.target.value);
  };

  async function sampleCreateCase() {
    try {
      await handleConfirm(caseName);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  return (
    <ModalWrapper closeModal={closeModal}>
      <div className="modal-body case-modal">
        <TitleClose
          title={t("Shared.Modals.AddCase.Title")}
          closeIcon={true}
          clickHandler={closeModal}
        />
        <div className="input-wrapper">
          <label className="medium">
            {t("General.Labels.Input.InputCase")}
            <input
              required
              value={caseName}
              onInput={handleCaseName}
              type="text"
              className="medium"
            />
          </label>
        </div>

        <TwoButtons
          firstButton={{ name: t("General.Buttons.Cancel"), func: closeModal }}
          secondButton={{
            name: t("Shared.Modals.AddCase.Buttons.AddCase"),
            func: sampleCreateCase,
            disabled: !caseName,
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddCaseModal;
